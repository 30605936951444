import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Circle from "../Circle";
import reactHtmlParser from "react-html-parser";


const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                <Row>
                    <Col>
                        <Circle/>
                        <h2>{reactHtmlParser(data?.section_data?.subtitle)}</h2>
                        {reactHtmlParser(data?.section_data?.description)}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    h2{
      color: #000000;
      margin-bottom: 40px;
    }
  p{
    margin-bottom: 21px;
    &:last-child{
      margin-bottom: 0;
    }
  }
`;

export default MyComponent;
