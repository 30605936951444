import React, {useEffect, useRef} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import Circle from "../Circle";
import reactHtmlParser from "react-html-parser";
import title from "../Title";
import {light} from "../../styles/globalStyleVars";
import { Swiper, SwiperSlide } from 'swiper/react'; // Correct import statement
import 'swiper/swiper-bundle.min.css'; // Correct import statement
import { Autoplay, Pagination, Navigation } from 'swiper'; // Correct import statement
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Certified = ({data}) => {

    const containerRef = useRef(null);

    return (
        <StyledComponent>
            <Img src={data?.images?.list?.find(e => e?.background === 'on')?.full_path}/>
            <Container className={'pb-150 pt-150'}>
                <Row >
                    <Col md={12}>
                        <div className={'content'}>
                            <Circle/>
                            <h2 className={'split-up'}>{reactHtmlParser(data?.section_data?.subtitle)}</h2>
                            <Col md={8}>
                                {reactHtmlParser(data?.section_data?.short_desc)}
                            </Col>
                        </div>
                    </Col>
                    <Col md={12}>
                        <Swiper
                            spaceBetween={30}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            modules={[Autoplay]}
                            breakpoints={{
                                767: {
                                    slidesPerView: 1 // Show 1 slide at a time on mobile
                                },
                                992: {
                                    slidesPerView: 2 // Show 2 slides at a time on tablets
                                },
                                1200: {
                                    slidesPerView: 3 // Show 3 slides at a time on large desktop screens
                                }

                            }}
                        >
                            {data?.posts?.list[0]?.images && data?.posts?.list[0]?.images?.length > 0 &&
                                data?.posts?.list[0]?.images?.map((e, i) => (
                                    <SwiperSlide key={i}>
                                        <div className={'content__box'}>
                                            <div className={'content__box__single'}>
                                                <img src={e?.full_path}/>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  overflow: hidden;
  .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h2{
      color: #FFFFFF;
      margin-bottom: 40px;
    }
    P{
      color: #ffffff;
      margin-bottom: 40px;
    }

  }
  .content__box{
    &__single{
      width: 100%;
      height: 250px;
      display: flex;
      justify-content: center;
      background-color: #ffffff;
    }
    @media(max-width: 767px){
      width: 100%;
      padding: 15px;
      &__single{
        height: 180px;
      }
    }
  }

  @media(max-width: 767px){
    .swiper-slide{
      width: 100%;
    }
  }
`;

export default React.memo(Certified);
