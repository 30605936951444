import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    console.log('data', data?.posts?.list)


    return (
    <StyledComponent className={'pb-150 parallax'}>
        <Container>
            {data?.posts?.list && data?.posts?.list?.length>0 &&
                data?.posts?.list?.map((row, index) => (
                <Row key={index + 1} className={`values pt-100 ${index % 2 === 1 ? 'even-row' : 'odd-row'}`}>
                    <Col md={index % 2 === 1 ? {span:5, offset:1} : 5} className={'values__content'}>
                        <div className={'content-wrapper'}>
                            <h2>{row?.data?.subtitle}</h2>
                            <p className={'split-up'}>{row?.data?.short_desc}</p>
                            <ul>
                                {reactHtmlParser(row?.data?.description)}
                            </ul>
                        </div>
                    </Col>
                    <Col
                        md={index % 2 === 1 ? 6 : {span:6, offset:1}}
                        className={'values__image'}
                    >
                        <div className={'img-wrapper reveal'}>
                            <Img src={row?.images?.[0]?.full_path} alt={row?.data?.subtitle} />
                        </div>
                    </Col>
                </Row>
            ))}
        </Container>
    </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .values{
      &:nth-child(even){
        flex-direction: row-reverse;
      }
      &__content{
        .content-wrapper{
          display: flex;
          flex-direction: column;
          justify-content: center; /* Vertical center alignment */
          height: 100%;
          h2{
            margin-bottom: 40px;
            color: #000000;
          }
          p{
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 40px;
            color: #636466;
          }
          ul{
            li{
              color: #000000;
              font-size: 16px;
              line-height: 24px;
              font-weight: 300;
              padding-bottom: 20px;
              margin-bottom: 20px;
              border-bottom: 1px solid rgb(99 100 102 / 25%);
              &:last-child{
                margin-bottom: 0;
              }
            }
          }
        }
      }
      &__image{
        .img-wrapper{
          position: relative;
          padding-top: calc(600 / 570 * 100%);
        }
      }
    }
`;

export default MyComponent;
