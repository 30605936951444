import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Circle from "../Circle";
import reactHtmlParser from "react-html-parser";


const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pb-150 pt-150'}>
            <Container>
                <Row>
                    <Col>
                        <Circle/>
                        <h2>{reactHtmlParser(data?.section_data?.subtitle)}</h2>
                        {reactHtmlParser(data?.section_data?.description)}
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #E9E9E9;
  h2{
   color: #000000;
  }
  table{
    margin-top: 40px;
    tr{
      display: flex;
      gap: 30px;
      margin-bottom: 20px;
      width: 100%;
      &:last-child{
        margin-bottom: 0;
      }
      td{
        width: 100%;
        height: auto;
        border-bottom: 1px solid #636466;
        p{
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          color: #000000;
          padding-bottom: 20px;
        }
      }
      @media(max-width: 767px){
        flex-direction: column;
      }
    }
  }

`;

export default MyComponent;