import React from 'react';
import styled from 'styled-components';
import {GoArrowLeft, GoArrowRight} from "react-icons/go";

const NavigationIcon = ({next_id, prev_id, color}) => {


    return (

        <StyledNavigationIcon className={'navigation_button'}>
            <ul>
                <li className='hover_left' id={next_id ? next_id : 'service-prev'}>

                    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 80 80">
                        <g id="Button_-_Nav" data-name="Button - Nav" transform="translate(80 80) rotate(180)">
                            <g id="Group_5548" data-name="Group 5548" transform="translate(-1208.5 -1787.5)">
                                <line id="Line_7" data-name="Line 7" x2="5" y2="5" transform="translate(1253.5 1822.5)" fill="none" stroke={`${color ? color : '#636466'}`} stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_8" data-name="Line 8" y1="5" x2="5" transform="translate(1253.5 1827.5)" fill="none" stroke={`${color ? color : '#636466'}`} stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_9" data-name="Line 9" x2="20" transform="translate(1238.5 1827.5)" fill="none" stroke={`${color ? color : '#636466'}`} stroke-linecap="round" stroke-width="1"/>
                            </g>
                            <g id="Ellipse_389" data-name="Ellipse 389" fill="none" stroke={`${color ? color : '#636466'}`} stroke-width="1">
                                <circle cx="40" cy="40" r="40" stroke="none"/>
                                <circle cx="40" cy="40" r="39.5" fill="none"/>
                            </g>
                            <g id="Ellipse_596" className={'Hover_Prev'} data-name="Ellipse 596" fill="none" stroke={`${color ? color : '#000000'}`} stroke-width="1" >
                                <circle cx="40" cy="40" r="40" stroke="none"/>
                                <circle cx="40" cy="40" r="39.5" fill="none"/>
                            </g>
                        </g>
                    </svg>

                </li>
                <li className='hover_right' id={prev_id ? prev_id : 'service-next'}>
                    <svg id="Button_-_Nav" data-name="Button - Nav" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 80 80">
                        <g id="Group_5548" data-name="Group 5548" transform="translate(-1208.5 -1787.5)">
                            <line id="Line_7" data-name="Line 7" x2="5" y2="5" transform="translate(1253.5 1822.5)" fill="none" stroke={`${color ? color : '#636466'}`} stroke-linecap="round" stroke-width="1"/>
                            <line id="Line_8" data-name="Line 8" y1="5" x2="5" transform="translate(1253.5 1827.5)" fill="none" stroke={`${color ? color : '#636466'}`} stroke-linecap="round" stroke-width="1"/>
                            <line id="Line_9" data-name="Line 9" x2="20" transform="translate(1238.5 1827.5)" fill="none" stroke={`${color ? color : '#636466'}`} stroke-linecap="round" stroke-width="1"/>
                        </g>
                        <g id="Ellipse_389" data-name="Ellipse 389" fill="none" stroke={`${color ? color : '#636466'}`} stroke-width="1">
                            <circle cx="40" cy="40" r="40" stroke="none"/>
                            <circle cx="40" cy="40" r="39.5" fill="none"/>
                        </g>
                        <g className={'Hover_Next'} id="Ellipse_596" data-name="Ellipse 596" fill="none" stroke={`${color ? color : '#000000'}`} stroke-width="1">
                            <circle cx="40" cy="40" r="40" stroke="none"/>
                            <circle cx="40" cy="40" r="39.5" fill="none"/>
                        </g>
                    </svg>

                </li>
            </ul>
        </StyledNavigationIcon>

    )
};


const StyledNavigationIcon = styled.div`
  position: relative;
  z-index: 10;

  ul {
    display: flex;

    li {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      overflow: hidden;

      &:nth-of-type(1) {
        margin-right: 15px;
      }

      svg {
        color: #3C3C3B;
        z-index: 2;
        .Hover_Prev, .Hover_Next{
          //stroke-dasharray: 0;
          //stroke-dashoffset: 0;
          transform: rotate(-90deg);
          stroke-dasharray: 0px 310px;
          transform-origin: 50% 50%;
          will-change: stroke-dashoffset,transform;
        }

        g {
          transition: 1.3s all cubic-bezier(.74,0,.24,.99);
        }
      }


      &:hover {
        .Hover_Prev {
          stroke-dasharray: 310px 310px;
          transform: none;
          //stroke-dasharray: 260px 260px;
          opacity: 1;
        }

        .Hover_Next {
          stroke-dasharray: 310px 310px;
          transform: none;
          //stroke-dasharray: 260px 260px;
          opacity: 1;
        }
      }

    }
  }



`;

export default React.memo(NavigationIcon);