import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import moment from "moment/moment";
import {Link} from "react-router-dom";

const MyComponent = ({data}) => {

    console.log('data', data)

    const formattedDate = new Date(data?.data?.date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });

    const [day, month, year] = formattedDate.split(' ');
    const formattedWithComma = `${day} ${month}, ${year}`;
    return (
        <StyledComponent>
            <section className="case_study_detail pb-150">
                <Container>
                    <Row>
                        <Col md={10}>
                            <div className="header">
                                <span>
                                   <Link to={`/news`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.207" height="11.414" viewBox="0 0 17.207 11.414">
                                          <g id="Group_20750" data-name="Group 20750" transform="translate(-2545.288 16565.707)">
                                            <line id="Line_4" data-name="Line 4" x2="16" transform="translate(2562 -16560) rotate(180)" fill="none" stroke="#636466" stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_5" data-name="Line 5" x2="5" y2="5" transform="translate(2551 -16555) rotate(180)" fill="none" stroke="#636466" stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_6" data-name="Line 6" y1="5" x2="5" transform="translate(2551 -16560) rotate(180)" fill="none" stroke="#636466" stroke-linecap="round" stroke-width="1"/>
                                          </g>
                                        </svg>
                                       <p>Back</p>
                                   </Link>
                                </span>
                                <h6>News</h6>
                                <h1>{reactHtmlParser(data?.data?.title)}</h1>
                                <p>{formattedWithComma}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container fluid className={'p-0'}>
                    <div className="case_study_image_wrapper">
                        <Img src={data?.images?.list?.find(f => f?.banner === 'on')?.full_path}/>
                    </div>
                </Container>

                <Container className={'position_relative'}>
                    <Row>
                        <Col md={10}>
                            <div className="blog_details">
                                {reactHtmlParser(data?.data?.body)}
                                <div className={'img-wrapp'}>
                                    <img src={data?.images?.list?.find(f => f?.short_desc !== '')?.full_path} alt=""/>
                                </div>
                                {reactHtmlParser(data?.images?.list?.find(f => f?.short_desc !== '')?.short_desc)}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
 
  .position_relative{
    position: relative;
  }
 
  .case_study_detail {
    padding-top: 160px;

    .header {
      margin-bottom: 40px;
      span{
       a{
         display: flex;
         align-items: center;
         margin-bottom: 24px;
         gap: 10px;
         transition: all 0.3s ease-in-out;
         p{
           font-size: 14px;
           line-height: 21px;
           color: #000;
         }
       }
        &:hover{
          a{
            gap: 15px;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      h6{
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        color: #000;
        margin-bottom: 5px;
      }
      h1{
        font-size: 24px;
        line-height: 36px;
        font-weight: 400;
        color: #000000;
        margin-bottom: 11px;
      }
    }

    .case_study_image_wrapper{
      position: relative;
      padding-top: calc(450 / 1366 * 100%);
      //margin-bottom: 120px;
    }
    
    .blog_details{
      padding-top: 80px;
      h3{
        color: #000000;
        margin-bottom: 77px;
      }
      
      p{
        color: #636466;
        margin-bottom: 0;
      }
      .img-wrapp{
        position: relative;
        margin-bottom: 60px;
        img{
          margin-top: 60px;
          height: 100%;
          width: 100%;

        }
      }
    }
  }
  
  
  .video_body{
    .container{
      padding: 0 !important;
    }
  }

  .social-vertical {
    top: 80px;
    position: absolute;
    left: 30px;

    p {
      margin-bottom: 20px;
      color: #4F616B;
    }
    
 

    @media (min-width: 1550px) {
      top: 15.3% !important;
    }
    @media (max-width: 767px) {
      top: unset !important;
      position: relative !important;
      left: unset !important;
      padding: 0 15px !important;
      margin-top: 30px;
    }
  }

  .social-lists {
    display: flex;
    flex-direction: column;
    width: auto;
    align-items: flex-start;
    @media (max-width: 767px) {
      flex-direction: row;

    }
    svg {
      height: 30px;
      width: 30px;
      circle{
        fill: transparent;
        //stroke: #D80028;
      }
      path{
        fill: #18A354;
      }
    }

    button {
      margin-bottom: 20px;
      @media (max-width: 767px) {
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 10px;
      }
      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

`;


export default React.memo(MyComponent);
