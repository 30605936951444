import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/Overview";
import Missionvision from "../../components/about/missionvision";
import Values from "../../components/about/Values";
import CeoMessage from "../../components/about/CeoMessage";
import MdMessage from "../../components/about/MdMessage";
import Clients from "../../components/Clients";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchAbout} from "../../api/redux/about";
import reactHtmlParser from "react-html-parser";

const MyComponent = () => {
    const dispath = useDispatch()
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, []);

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS
        let param = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: 'about-us',
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
            [ApiParam.GALLERY]: 'yes',
        }
        dispath(fetchAbout([api_url, param]))
    }, [dispath])


    let getPost = useSelector(store => store.aboutReducer)


    //refactor
    let page_data = getPost?.posts?.data?.page_data;
    let banner = getPost?.posts?.data?.sections?.find(e => e?.section_data?.slug === 'about-banner');
    let overview = getPost?.posts?.data?.sections.find((f)=> f.section_data?.slug === 'about-overview');
    let mission = getPost?.posts?.data?.sections.find((f)=> f.section_data?.slug === 'business-volume');
    let values = getPost?.posts?.data?.sections.find((f)=> f.section_data?.slug === 'zigzag');
    let md = getPost?.posts?.data?.sections.find((f)=> f.section_data?.slug === 'md-message');
    let ceo = getPost?.posts?.data?.sections.find((f)=> f.section_data?.slug === 'ceo-message');
    let clients_data = getPost?.posts?.clients?.page_data;
    let clients_list = getPost?.posts?.clients?.posts?.list;

    console.log('page', page_data)

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? `${page_data?.meta_title} | Probridhi Apparels Ltd.` : 'Probridhi Apparels Ltd.'}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    title={reactHtmlParser(banner?.section_data?.subtitle)}
                    img={banner?.images?.list?.[0]?.full_path}/>
                <Overview
                    bigImage={overview?.images?.list?.find((e)=> e?.bottom === "on")?.full_path}
                    smallImage={overview?.images?.list?.find((e)=> e?.top === "on")?.full_path}
                    title={reactHtmlParser(overview?.section_data?.subtitle)}
                    subtitle={reactHtmlParser(overview?.section_data?.short_desc)}
                    desc={reactHtmlParser(overview?.section_data?.description)}
                />
                <Missionvision data={mission}/>
                <Values data={values}/>
                <CeoMessage data={ceo}/>
                <MdMessage data={md}/>
                <Clients data={clients_data} list={clients_list}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
