import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "./Img";
import {Link} from "react-router-dom";

const MyComponent = ({img, title, type, date, url}) => {
    const formattedDate = new Date(date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });

    const [day, month, year] = formattedDate.split(' ');
    const formattedWithComma = `${day} ${month}, ${year}`;

    return (
        <StyledComponent className={'newsbox'} >
            <Link to={url ? url : ''}>
                <div className={'newsbox__img'}>
                    <Img src={img ? img : ''}/>
                </div>
                <div className={'newsbox__content'}>
                    <h4 className={'split-up'}>{title ? title : ''}</h4>
                    <span>
                    <h6>
                        {type ? type : ''}
                    </h6>
                    <p>
                        {date ? formattedWithComma : ''}
                    </p>
                </span>
                </div>
            </Link>

        </StyledComponent>
    );
};

const StyledComponent = styled.div`
    .newsbox{
      &__img{
        position: relative;
        padding-top: calc(340 / 570 * 100%);
      }
      &__content{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        h4{
          width: 75%;
          font-size: 24px;
          line-height: 36px;
          font-weight: 400;
          color: #000;
        }
        span{
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          h6{
            color: #000;
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
          }
          p{
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
          }
        }
      }
      @media(max-width: 767px){
        &__img{
          padding-top: calc(220 / 345 * 100%);
        }
        &__content{
          flex-direction: column-reverse;
          span{
            flex-direction: row;
            -webkit-box-pack: justify;
            justify-content: space-between;
            width: 100%;
          }
          h4{
            margin-top: 15px;
            width: 100%;
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }
`;

export default MyComponent;
