import React, {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import styled from "styled-components";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Banner from "../../components/home/Banner";
import Overview from "../../components/Overview";
import Collection from "../../components/home/Collection";
import Certified from "../../components/home/Certified";
import Clients from "../../components/Clients";
import LatestNews from "../../components/home/LatestNews";
import NewsBox from "../../components/NewsBox";
import {useLocation} from "react-router-dom";
import {gsap} from "gsap";
import {fetchHome} from "../../api/redux/home";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import reactHtmlParser from "react-html-parser";
import latestNews from "../../components/home/LatestNews";



const Home = () => {

    const dispath = useDispatch()
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, []);

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS
        let param = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: 'home',
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
            [ApiParam.GALLERY]: 'yes',
        }
        dispath(fetchHome([api_url, param]))
    }, [dispath])


    let getPost = useSelector(store => store.homeReducer)



    //scroll to overview

    const scrollToOverview = () => {
        const overviewSection = document.getElementById('overview');
        if (overviewSection) {
            // Calculate the target scroll position relative to the viewport
            const scrollTarget = overviewSection.offsetTop; // Adjust as needed

            // Scroll to the target position with smooth behavior
            window.scrollTo({
                top: scrollTarget,
                behavior: 'smooth',
            });
        }
    };



    //refactor
    let page_data = getPost?.posts?.data?.page_data;
    let banner = getPost?.posts?.data?.sections.find((f)=> f.section_data?.slug === 'home-banner-slider');
    let overview = getPost?.posts?.data?.sections.find((f)=> f.section_data?.slug === 'home-overview');
    let collections = getPost?.posts?.data?.sections.find((f)=> f.section_data?.slug === 'home-collections');
    let certified = getPost?.posts?.data?.sections.find((f)=> f.section_data?.slug === 'home-certified');
    let clients_data = getPost?.posts?.clients?.page_data;
    let clients_list = getPost?.posts?.clients?.posts?.list;
    let blog_title = getPost?.posts?.data?.sections.find((f)=> f.section_data?.slug === 'home-news')?.section_data?.short_desc;
    let blog_list = getPost?.posts?.blog_list;



    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.meta_title ? page_data?.meta_title : 'Probridhi Apparels Ltd'}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>
            <StyledSection>
                <Banner onClick={scrollToOverview} data={banner} title={banner?.section_data?.subtitle}/>

                <Overview
                    bigImage={overview?.images?.list?.find((e)=> e?.bottom === "on")?.full_path}
                    smallImage={overview?.images?.list?.find((e)=> e?.top === "on")?.full_path}
                    title={reactHtmlParser(overview?.section_data?.subtitle)}
                    subtitle={reactHtmlParser(overview?.section_data?.short_desc)}
                    desc={reactHtmlParser(overview?.section_data?.description)}
                    url
                />

                {
                    collections &&
                    <Collection data={collections}/>
                }
                {
                    certified &&
                    <Certified data={certified}/>
                }
                {
                    clients_list && clients_data &&
                    <Clients data={clients_data} list={clients_list}/>
                }
                {
                    blog_title && blog_list &&
                    <LatestNews list={blog_list} title={blog_title}/>
                }

            </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`

`;

export default Home;
