import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

export const ImgInnerBanner = ({
                        src,
                        position,
                        objectFit,
                        height,
                        width,
                        banner,
                        alt,
                        left,
                        margin,
                        right,
                        top,
                        bottom,
                    }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const imgRef = useRef(null);

    // const onImageLoad = () => {
    //     gsap.fromTo(imgRef.current, { opacity: 0 }, { opacity: 1, duration: 1 });
    // };
    const onImageLoad = () => {
        gsap.fromTo(
            imgRef.current,
            { y: -20, opacity: 0, ease: "power4.inOut", },
            { y: 0, opacity: 1, duration:2, ease: "power4.inOut", }
        );
    };

    // const handleIntersection = (entries, observer) => {
    //     entries.forEach((entry) => {
    //         if (entry.isIntersecting) {
    //             // Image is in the viewport
    //             if (imageSrc !== src) {
    //                 // Check if src is not already set
    //                 setImageSrc(src);
    //             }
    //             observer.unobserve(entry.target); // Stop observing once the image is in view
    //         }
    //     });
    // };
    //
    // useEffect(() => {
    //     const options = {
    //         root: null, // viewport
    //         rootMargin: '0px',
    //         threshold: 0.1, // 10% of the image must be in the viewport
    //     };
    //
    //     const observer = new IntersectionObserver(handleIntersection, options);
    //
    //     if (imgRef.current) {
    //         observer.observe(imgRef.current);
    //     }
    //
    //     return () => {
    //         // Cleanup the observer when the component unmounts
    //         observer.disconnect();
    //     };
    // }, [src]);

    return (
        <StyledImg
            className={`global-image`}
            objectFit={objectFit}
            margin={margin}
            position={position}
            left={left}
            right={right}
            top={top}
            bottom={bottom}
            height={height}
            width={width}
        >
            <img
                data-speed={0.9}
                ref={imgRef}
                src={src} // Use 'imageSrc' directly
                alt={alt || ''}
                onLoad={onImageLoad}
            />
        </StyledImg>
    );
};


const StyledImg = styled.div`
  position: ${(props) => props.position || 'absolute'};
  height: ${(props) => props.height || '100%'};
  width: ${(props) => props.width || '100%'};
  top: ${(props) => props.top || 0};
  left: ${(props) => props.left || 0};
  bottom: ${(props) => props.bottom || 0};
  right: ${(props) => props.right || 0};
  margin: ${(props) => props.margin || 0};

  .lazyload-wrapper {
  position: ${(props) => props.position || 'absolute'};
  height: ${(props) => props.height || '100%'};
  width: ${(props) => props.width || '100%'};
  top: ${(props) => props.top || 0};
  left: ${(props) => props.left || 0};
  bottom: ${(props) => props.bottom || 0};
  right: ${(props) => props.right || 0};
  margin: ${(props) => props.margin || 0};

    /* styles.css (or your styled-components file) */
    .transition-img {
      opacity: 1;
      transition: opacity 1s ease-in-out; /* You can adjust the duration and easing function as per your preference */
    }

    .transition-img-hidden {
      opacity: 0;
    }



  }

  img {
    width: 103%;
    height: 105%;
    object-fit: ${props => props.objectFit || 'cover'};
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    //@media (max-width: 1024px){
    //  top: calc(-30 / 2 * 7px);
    //}
    @media (min-width: 821px) and (max-width: 1024px){
      top: calc(-32 / 2 * 7px);
    }
    @media (min-width: 790px) and (max-width: 820px){
      top: calc(-30 / 2 * 7px);
    }
    @media (min-width: 768px) and (max-width: 789px){
      top: calc(-26 / 2 * 7px);
    }
    //@media (min-width: 1251px) and (max-width: 1350px){
    //  top: calc(-13 / 2 * 7px);
    //}
    //@media (min-width: 1351px){
    //  top: calc(-6 / 2 * 7px);
    //}
  }

}`;
