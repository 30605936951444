import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../ParallaxBg";
import Circle from "../Circle";
import {ImgInnerBanner} from "../ImgInnerBanner";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    console.log('data', data)
    return (
        <StyledComponent className={'mission-vision pt-150'}>
            <Img src={data?.images?.list[0]?.full_path} alt={data?.section_data?.subtitle}/>
            <Container>
                <Row className={'mission-vision__text'}>
                    <Col md={12} className={'top'}>
                        <Circle/>
                        <h2 className={'split-up'}>{reactHtmlParser(data?.section_data?.subtitle)}</h2>
                    </Col>
                    <Col md={4} className={''}>
                        <div className={'box__content'}>
                            <p>{data?.posts?.list?.find(e => e?.data?.slug === 'projection')?.data?.subtitle}</p>
                            <h2>{data?.posts?.list?.find(e => e?.data?.slug === 'projection')?.data?.short_desc}</h2>
                            <p>{data?.posts?.list?.find(e => e?.data?.slug === 'projection')?.data?.description}</p>
                        </div>
                    </Col>
                    <Col md={4} className={''}>
                        <div className={'box__content'}>
                            <p>{data?.posts?.list?.find(e => e?.data?.slug === 'growth')?.data?.subtitle}</p>
                            <h2>{data?.posts?.list?.find(e => e?.data?.slug === 'growth')?.data?.short_desc}</h2>
                            <p>{data?.posts?.list?.find(e => e?.data?.slug === 'growth')?.data?.description}</p>
                        </div>
                    </Col>
                    <Col md={4} className={''}>
                        <div className={'box__content'}>
                            <p>{data?.posts?.list?.find(e => e?.data?.slug === 'production')?.data?.subtitle}</p>
                            <h2>{data?.posts?.list?.find(e => e?.data?.slug === 'production')?.data?.short_desc}</h2>
                            <p>{data?.posts?.list?.find(e => e?.data?.slug === 'production')?.data?.description}</p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div className="mission-vision__box">
                <Container>
                    <Row>
                        <Col>
                            <div className="mission-vision__box__inner d-flex flex-wrap">
                                <Col className={'p-0'} >
                                    <div className={'mission-vision__box__inner__content'}>
                                        <h2>{data?.posts?.list?.find(e => e?.data?.slug === 'vision')?.data?.subtitle}</h2>
                                        {reactHtmlParser(data?.posts?.list?.find(e => e?.data?.slug === 'vision')?.data?.description)}
                                    </div>
                                </Col>

                                <Col className={'p-0'}>
                                    <div className={'mission-vision__box__inner__content'}>
                                        <h2>{data?.posts?.list?.find(e => e?.data?.slug === 'mission')?.data?.subtitle}</h2>
                                        {reactHtmlParser(data?.posts?.list?.find(e => e?.data?.slug === 'mission')?.data?.description)}
                                    </div>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
 .container{
   overflow: hidden;
 }
  .mission-vision__text {
    margin-bottom: 150px;
    .top{
      margin-bottom: 40px;
      h2{
        color: #ffffff;
      }
    }
    .box{
      &__content{
        background-color: rgb(255 255 255 / 10%);
        backdrop-filter: blur(30px);
        filter: brightness(1.15);
        padding: 68px 40px;
        //border: 1px solid #636466;
        p{
          color: #ffffff;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          margin-bottom: 7px;
          &:last-child{
            margin-bottom: 0;
          }
        }
        h2{
          color: #ffffff;
          margin-bottom: 5px;
          @media (min-width: 1550px) {
            font-size: 60px;
            line-height: 70px;
          }
          // Primary/Laptop
          @media (max-width: 1549px) {
            font-size: 60px;
            line-height: 70px;
          }
          // Mobile
          @media (max-width: 767px) {
            font-size: 40px;
            line-height: 70px;
          }
        }
        @media(max-width: 767px){
          padding: 30px 20px;
          margin-bottom: 15px;
        }
      }
    }
  }

  .mission-vision__box {
    position: relative;
    height: 100%;
    width: 100%;
    margin-top: -1px;
    &:after {
      content: '';
      position: absolute;
      height: 50%;
      background-color: #ffffff;
      left: 0;
      bottom: -1px;
      width: 100%;
    }

    &__inner {
      position: relative;
      z-index: 2;
      gap: 30px;
      .col{
        border: 1px solid rgb(99 100 102 / 25%);
      }
      &__content{
        padding: 60px 30px;
        height: 100%;
        width: 100%;
        background-color: #ffffff;
        h2 {
          color: #000000;
          margin-bottom: 40px;
          text-transform: capitalize;
        }

        p {
          color: #636466;
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          margin-bottom: 20px;
          &:last-child{
            margin-bottom: 0;
          }
        }
        @media(max-width: 767px){
          padding: 30px 20px;
        }
      }
      @media(max-width: 767px){
        gap: 15px;
      }
    }
  }

  @media (max-width: 991px) {
    .mission-vision__text {
      margin-bottom: 100px;

      p {
        width: 100%;
      }
    }

    .mission-vision__box__inner {
     padding-top: 40px;
    }
  }

  @media (max-width: 769px) {
    .mission-vision__text{
      .box{
        margin-bottom: 20px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .mission-vision__box__inner {
    padding-top: 40px;
      background-color: #ffffff;
      p {
        width: 100%;
        max-width: unset;
      }

    }
  }

  @media (max-width: 650px) {
    .title {
      margin-bottom: 30px;
    }

    .text-center {
      text-align: left !important;
    }

    .mission-vision__box__inner {
      .col {
        min-width: 100%;
        //margin-bottom: 40px;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }
      }

      h2 {
        font-size: 24px;
        line-height: 50px;
        margin-bottom: 10px;
      }

    }

    .mission-vision__text {
      margin-bottom: 80px;
    }
  }

  @media(max-width: 767px){
    .mission-vision__box{
      background-color: white;
    }
  }
`;

export default MyComponent;
