import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import Title from "../Title";
import {hover, text} from "../../styles/globalStyleVars";
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css';
import ReactHtmlParser from "react-html-parser";
import reactHtmlParser from "react-html-parser";
import {gsap, Power2} from "gsap";

const MyComponent = ({data}) => {
    console.log('data', data)
    // const videoUrlId = data?.url?.replaceAll('https://youtu.be/', '');
    const videoUrlId = data?.images?.list?.find(f => f.background !== 'on')?.short_title;
    // offset
    const [offset, setOffset] = useState(90)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)
    }, [])

    let [videoId, setVideo] = useState("");
    let [open, setOpen] = useState(false);

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };

    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        setBackgroundImage(data?.images?.list?.find((e)=>e?.background === "on")?.full_path);
    }, [data]);


    const namedes = data?.section_data?.short_desc;
    let name, desig;

    if (namedes) {
        const splitParts = namedes.split(', ');

        // Ensure splitParts has at least two elements before accessing them
        if (splitParts.length >= 2) {
            name = splitParts[0];
            desig = splitParts[1];
        } else {
            // Handle the case where there are not enough parts
            name = namedes;
            desig = ""; // or set it to some default value
        }
    } else {
        // Handle the case where namedes is undefined
        name = "";
        desig = "";
    }

    console.log("Name:", name);
    console.log("Designation:", desig);



    return (
        <>
            <StyledComponent offset={offset} className={'ceo'} bg={backgroundImage}>
                <ModalVideo channel='youtube' autoplay isOpen={open} videoId={videoUrlId}
                            onClose={() => handelOpen(false, '')}/>
               <Container fluid>
                   <Row>
                       <Col sm={{span:5, offset:1}} className={'pl-0 ceo__content'}>
                           <div className={'ceo__content__box'}>
                               <h2>{data?.section_data?.subtitle}</h2>
                               <div>
                                   {reactHtmlParser(data?.section_data?.description)}
                               </div>
                               <h3 className={'fade-up'}>{ReactHtmlParser(name)}</h3>
                               <h6 className={'fade-up'}>{reactHtmlParser(desig)}</h6>
                           </div>
                       </Col>
                       <Col sm={{span:5, offset:1}} className={'pr-0'}>
                           {
                               videoUrlId &&
                               <div className="ceo__video box" onClick={() => handelOpen(true, videoUrlId)}>
                                   {/*<svg width="24" height="32" viewBox="0 0 24 32">*/}
                                   {/*    <path id="Polygon_2" data-name="Polygon 2" d="M16,0,32,24H0Z"*/}
                                   {/*          transform="translate(24) rotate(90)" fill="#f2eee8"/>*/}
                                   {/*</svg>*/}
                                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 32">
                                       <path id="Polygon_2" data-name="Polygon 2" d="M14.336,2.5a2,2,0,0,1,3.328,0L29.927,20.891A2,2,0,0,1,28.263,24H3.737a2,2,0,0,1-1.664-3.109Z" transform="translate(24) rotate(90)" fill="#f2eee8"/>
                                   </svg>

                                   <p>Play <br/>
                                       Video</p>
                               </div>
                           }

                           <div className="ceo__img">
                               <Img src={data?.images?.list?.find(f => f.background !== 'on')?.full_path} alt={data?.section_data?.subtitle}/>
                           </div>
                       </Col>
                   </Row>
               </Container>
            </StyledComponent>
        </>
    );
};

const StyledComponent = styled.section`
  overflow: hidden;
  position: relative;
  background-image: url("${props=> props.bg}");
  background-size: auto;
  background-repeat: repeat;
  height: 100%;
  //&:before{
  //  position: absolute;
  //  content: '';
  //  top: 0;
  //  left: 0;
  //  height: 100%;
  //  width: 100%;
  //  //background-color: #505153;
  //  background-color: rgba(80, 81, 83, 0.75);
  //}

  .col-sm-5 {
    position: relative;
  }

  .ceo__img {
    padding-top: calc(768 / 598 * 100%);
    position: relative;
    min-height: 100%;
  }

  .ceo__video {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: ${hover};
    position: absolute;
    top: 0;
    bottom: 0;
    left: -25px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    cursor: pointer;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    

    &:after {
      content: '';
      position: absolute;
      height: 80px;
      width: 80px;
      border-radius: 100%;
        // background-color: ${hover};
    }

    @keyframes big {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgb(143, 109, 79);
      }

      40% {
        transform: scale(1);
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
      }

      70% {
        transform: scale(1.2);
        box-shadow: 0 0 0 25px rgba(0, 0, 0, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }

    svg {
      position: relative;
      z-index: 2;
      transition: all .3s ease;
      transform: none;
      left: 5px;
    }

    p {
      font-size: 12px;
      font-weight: bold;
      line-height: 12px;
      color: #f2eee8;
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
      position: absolute;
      transition: all .3s ease;
      transform: translateY(10px);
    }

    &.hover {
      &:after {

        border-radius: 50%;
      }
    }

    &:hover {
      &:after {
        animation: big 2.5s ease infinite;
      }

      svg {
        opacity: 0;
        transform: translateY(-10px);
      }

      p {
        opacity: 1;
        transform: none;
      }
    }

  }

  .ceo__content {
    padding-bottom: 20px;
    position: relative;
    padding-left: ${props => props.offset}px;
    &__box{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      height: 100%;
      h2{
        color:#ffffff;
        margin-bottom: 60px;
      }
      h3{
        margin-top: 40px;
        margin-bottom: 10px;
        color:#ffffff;
      }
      p{
        color:#ffffff;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 21px;
        &:last-child{
          margin-bottom: 0;
        }
        
        @media(min-width: 1920px){
          font-size: 16px;
          line-height: 24px;
        }
      }
      h6{
        color: rgb(255 255 255 / 50%);
      }
    }
  }
  
  @media (max-width: 991px) {
    .col-sm-5 {
      min-width: 60%;
    }

    .ceo__content {
      min-width: 100%;
      margin: 0;
      padding-left: 30px !important;
      margin-top: 40px;
    }
  }

  @media (max-width: 767px) {
    .row{
      flex-direction: column-reverse;
    }
    .col-sm-5 {
      min-width: 100%;
      padding-left: 0;
    }

    .ceo__video {
      top: auto;
      left: 0;
      right: 0;
      margin: auto;
      //height: 60px;
      //width: 60px;
      bottom: -40px;

      //svg {
      //  height: 20px;
      //}
    }

    .ceo__content {
      min-width: 100%;
      margin: 80px  0;
      padding-left: 30px !important;
      padding-right: 30px !important;

      .title h2 {
        margin-top: 80px;
        font-size: 28px !important;
        line-height: 32px !important;
      }

      h5 {
        margin-top: 24px;
        font-size: 20px;
        line-height: 28px;
      }

      h6 {
        font-size: 14px;
        line-height: 18px;
      }
      @media(max-width: 767px){
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
    
    
  }

  .modal-video{
    position: absolute;
  }
`;

export default MyComponent;
