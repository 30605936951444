import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Col, Container, Row} from "react-bootstrap";
import Button from "../components/Button";
import Circle from "../components/Circle";
import Test from "../components/Test";

const MyComponent = () => {

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Title</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
               <Test/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  padding-top: 80px;

    h1{
      margin-top: 20px;
      margin-bottom: 20px;
    }h2{
      margin-bottom: 20px;
    }h3{
      margin-bottom: 20px;
    }h4{
      margin-bottom: 20px;
    }h5{
      margin-bottom: 20px;
    }h6{
       margin-bottom: 20px;
     }
  p{
    padding-bottom: 20px;
    border-bottom: 1px solid #636466;
  }
    
`;

export default MyComponent;
