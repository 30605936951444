import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Circle from "../Circle";
import Button from "../Button";
import VideoBanner from "../VideoBanner";
import reactHtmlParser from "react-html-parser";

const HomeBanner = ({ src, alt, onClick, data, title }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let desktop = data?.images?.list?.find(f => f.desktop === 'on')?.full_path;
    let mobile = data?.images?.list?.find(f => f.mobile === 'on')?.full_path;
    let fallback = data?.images?.list?.find(f => f.background === 'on')?.full_path;

    const [videoLoaded, setVideoLoaded] = useState(false);

    const handleVideoLoad = () => {
        setVideoLoaded(true);
    };

    return (
        <StyledComponent>
            {!videoLoaded && <img className={'fallback'} src={fallback} alt="fallback" />}
            <VideoBanner
                src={windowWidth > 767 ? desktop : mobile}
                onLoadedData={handleVideoLoad}
            />
            <div className={'content'}>
                <Circle />
                {/*<h1>{title ? reactHtmlParser(title) : 'Where fashion meets <br /> function in every stitch'}</h1>*/}
                <h1>{title ? reactHtmlParser(title)  : 'Where fashion meets <br/> function in every stitch'}</h1>
                <Button text={'Explore Products'} src={'/product/'} borderColor={'#FFFFFF'} color={'#FFFFFF'} hoverColor={'#FFFFFF'} />
            </div>
            {windowWidth < 767 && (
                <div className={'scroll'}>
                    <p onClick={onClick}>Scroll</p>
                </div>
            )}
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  height: 100vh;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
    object-fit: ${props => props.objectFit || 'cover'};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .fallback {
    width: 100%;
    height: 100%;
    object-fit: ${props => props.objectFit || 'cover'};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .circle {
      background-color: #ffffff !important;
    }

    h1 {
      margin-bottom: 40px;
      @media (max-width: 767px) {
        font-size: 40px;
        line-height: 50px;
      }
    }
    
    @media(max-width: 767px){
      width: 100%;
    }
  }

  .scroll {
    position: absolute;
    bottom: 40px;
    left: 0px;
    z-index: 999999;
    transform: rotate(-90deg);

    p {
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
`;

export default React.memo(HomeBanner);
