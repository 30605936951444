export const apiEndPoints = {
    POSTS: 'get-req-data/posts',
    PHOTOS: 'get-req-data/photos',
    PAGE_DATA:'get-req-data/page-data',
    SECTIONS: 'get-req-data/sections',
    PAGE_POST_DATA: 'get-req-data/page-post-data',
    ALL_CATEGORY: 'get-req-data/all-category',
    CHILD_CATEGORY: 'get-req-data/child-category',
    PRODUCT_DATA: 'get-req-data/product-by-category',
    PRODUCT_DETAILS: 'get-req-data/product-data',
    BLOG_DETAILS: 'get-req-data/blog-data',
    FILTER_PROJECT : 'get-req-data/product-by-cats',
    SUBMITFORM: 'post-req-data/form-submit',
    POST_FORM: 'post-req-data/form-submit',
    SOCIAL: 'get-req-data/settings-data',
};
