import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    globalLoader: true,
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        loading: (state) => {
            state.globalLoader = true
        },
        loaded: (state) => {
            state.globalLoader = false
        }
    },
    extraReducers: {
        ['fetchData/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchHome/fullfilled']: (state, action)=>{
            state.globalLoader = false
        },
        ['fetchAbout/fullfilled']: (state, action)=>{
            state.globalLoader = false
        },
        ['fetchProducts/fullfilled']: (state, action)=>{
            state.globalLoader = false
        },
        ['fetchProductPage/fullfilled']: (state, action)=>{
            state.globalLoader = false
        },
        ['fetchProductDetails/fullfilled']: (state, action)=>{
            state.globalLoader = false
        },
        ['fetchFactory/fullfilled']: (state, action)=>{
            state.globalLoader = false
        },
        ['fetchSustainability/fullfilled']: (state, action)=>{
            state.globalLoader = false
        },
        ['fetchNews/fullfilled']: (state, action)=>{
            state.globalLoader = false
        },
        ['fetchNewsDetails/fullfilled']: (state, action)=>{
            state.globalLoader = false
        },
        ['fetchCareer/fullfilled']: (state, action)=>{
            state.globalLoader = false
        },
        ['fetchContact/fullfilled']: (state, action)=>{
            state.globalLoader = false
        },
    }
})


export default globalSlice.reducer
export const {loaded, loading} = globalSlice.actions
