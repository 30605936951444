import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import "react-modal-video/css/modal-video.min.css";
import Button from "./Button";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {fetchSocial} from "../api/redux/social";
import reactHtmlParser from "react-html-parser";
const MyComponent = () => {

    //api call
    const dispath = useDispatch()
    useEffect(() => {
        let api_url = apiEndPoints.SOCIAL
        dispath(fetchSocial([api_url]))
    }, [dispath])


    let getPost = useSelector(store => store.socialReducer)


    return (
        <StyledComponent className="footer">
            <Container>
                <div className={'top'}>
                    <h2>Reach out to us today</h2>
                    <Button text={'Contact'} src={'/contact'} color={'#ffffff'} borderColor={'#ffffff'} hoverColor={'#ffffff'}/>
                </div>
                <Row>
                    <Col sm={3} className="footer__logo">
                        <Link to={'/'}>
                            <img src={'/images/static/logo-footer.svg'} alt="logo" />
                        </Link>
                        <p>
                            We are proudly crafting 100% export-oriented, top-quality sweaters tailored to your specifications. A joint venture of Hong Kong, Bangladesh, & France.
                        </p>
                    </Col>
                    <Col className="footer-info " sm={{span: 3, offset:1}}>
                        <p>Enquiries</p>
                        <ul>
                            <li>
                                <a href={`https://www.google.com/maps?q=${getPost?.posts?.data?.office_location}`} target={"_blank"}>
                                    {getPost?.posts?.data?.office_location}
                                </a>
                            </li>
                            <li>
                                <a href={`tel:${getPost?.posts?.data?.office_phone}`} target={"_blank"}>
                                    {getPost?.posts?.data?.office_phone}
                                </a>
                            </li>
                            <li>
                                <a href={`mailto:${getPost?.posts?.data?.contact_email}`} target={"_blank"}>
                                    {getPost?.posts?.data?.contact_email}
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col className="footer-page " sm={2}>
                        <p>Explore</p>
                        <ul>
                            <li>
                                <Link to={"/about"}>
                                    About Us
                                </Link>
                            </li>
                            <li>
                                <Link to={"/product"}>
                                    Product
                                </Link>
                            </li>
                            <li>
                                <Link to={"/factory-profile"}>
                                    Factory Profile
                                </Link>
                            </li>
                            <li>
                                <Link to={"/sustainability"}>
                                    Sustainability
                                </Link>
                            </li>
                            <li>
                                <Link to={"/news"}>
                                    News & Events
                                </Link>
                            </li>
                            <li>
                                <Link to={"/career"}>
                                    Career
                                </Link>
                            </li>
                            <li>
                                <Link to={"/contact"}>
                                    Contact Us
                                </Link>
                            </li>
                            {/*<li><Link to={'/'}>Annual Reports</Link></li>*/}
                        </ul>
                    </Col>
                    <Col className="footer-social  m-dn" sm={{offset:1, span:2}}>
                        <p>Social</p>
                        <ul>
                            <li>
                                <a href={getPost?.posts?.data?.facebook} target={"_blank"}>
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a href={getPost?.posts?.data?.instagram} target={"_blank"}>
                                    Instagram
                                </a>
                            </li>
                            <li>
                                <a href={getPost?.posts?.data?.youtube} target={"_blank"}>
                                    Youtube
                                </a>
                            </li>
                            <li>
                                <a href={getPost?.posts?.data?.linkedin} target={"_blank"}>
                                    Linkedin
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>

                <Row className="copyright">
                    <Col className="d-flex justify-content-between">
                        <p>© {moment(Date()).format("YYYY")} Probridhi Apparels Ltd. All Rights Reserved.</p>
                        {/*<p>Designed & Developed by Brandmyth Digital</p>*/}
                        <a href={"https://dcastalia.com/"} target="_blank">
                            Design & Developed by Dcastalia </a>

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};


const StyledComponent = styled.section`
   position: relative;
   overflow: hidden;
   z-index: 99;
  padding-top: 80px;
  background-image: url("/images/static/pattern/black.png");
  background-size: auto;
  background-repeat: repeat;
  height: 100%;
  &:before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    //background-color: #505153;
    background-color: rgba(80, 81, 83, 0.75);
  }
  
  .container{
    position: relative;
    z-index: 100;
  }

   .top{
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding-bottom: 40px;
     margin-bottom: 80px;
     border-bottom: 1px solid rgb(255 255 255 / 25%);
     h2{
       color: #FFFFFF;
     }
     
     @media(max-width: 767px){
       padding-bottom: 40px;
       margin-bottom: 40px;
       align-items: flex-end !important;
       h2{
         width: 70%;
       }
     }
   }

   .footer__logo {
      p {
        font-size: 14px;
        line-height: 21px;
         margin-top: 30px;
         color: rgba(255, 255, 255, 0.8);
      }

      ul {
         margin-top: 80px;
         display: flex;

         li {
            margin-bottom: 0 !important;

            &:not(:nth-last-of-type(1)) {
               margin-right: 15px;
            }

            &:hover {
            }
         }
      }
     @media(max-width: 767px){
       margin-bottom: 75px !important;
     }
   }
   .footer-info{
     p{
       margin-bottom: 35px;
       font-size: 14px;
       line-height: 20px;
       font-weight: 400;
       color: rgb(255 255 255 / 50%);
     }
     ul{
       li{
         margin-bottom: 15px !important;
         a{
           color: #ffffff;
           font-size: 14px;
           line-height: 20px;
           font-weight: 400;
         }
         &:first-child{
           margin-bottom: 35px !important;
          @media(max-width: 767px){
            width: 80%;
          }
         }
         &:last-child{
           margin-bottom: 0 !important;
         }
         &:hover{
           a{
             color: rgb(255 255 255 / 50%) !important;
             text-decoration: underline;
           }
         }
       }
     }
     @media(max-width: 767px){
       width: 100%;
       margin-bottom: 30px;
       p{
         margin-bottom: 15px;
       }
       ul{
         li{
           &:first-child{
             margin-bottom: 20px !important;
           }
         }
       }
     }
   }
  .footer-page{
    p{
      margin-bottom: 35px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: rgb(255 255 255 / 50%);
    }
    ul{
      li{
        margin-bottom: 15px !important;
        a{
          color: #ffffff;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
        }
        &:last-child{
          margin-bottom: 0 !important;
        }
        &:hover{
          a{
            color: rgb(255 255 255 / 50%) !important;
            text-decoration: underline;
          }
        }
      }
    }
    @media(max-width: 767px){
      width: 50%;
      margin-bottom: 30px;
      p{
        margin-bottom: 20px;
      }
    }
  }
  .footer-social{
    p{
      margin-bottom: 35px;
      opacity: 1;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: rgb(255 255 255 / 50%);
    }
    ul{
      li{
        margin-bottom: 15px !important;
        a{
          color: #ffffff;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
        }
        &:last-child{
          margin-bottom: 0 !important;
        }
        &:hover{
          a{
            color: rgb(255 255 255 / 50%) !important;
            text-decoration: underline;
          }
        }
      }
    }
    @media(max-width: 767px){
      width: 50%;
      margin-bottom: 30px;
      p{
        opacity: 1;
        margin-bottom: 20px;
      }
    }
  }


   .copyright {
      padding-top: 30px;
      margin-top: 60px;
      position: relative;
     padding-bottom: 45px;
     //border-top: 1px solid rgb(255 255 255 / 25%);
      &:before {
         position: absolute;
         content: "";
         top: 0;
         left: 15px;
         right: 15px;
         height: 1px;
        background-color: rgb(255 255 255 / 25%);
      }

      p{
        color: #ffffff;
      }
      a {
         color: #ffffff;
        &:hover{
          color: rgb(255 255 255 / 50%) !important;
        }
      }
   }

  

   @media (max-width: 767px) {
      .footer__logo {
         min-width: 100%;
         margin-bottom: 30px;

         p {
            margin-top: 40px;
            padding-bottom: 30px;
            border-bottom: 1px solid #fff;
         }

         ul {
            display: none;
         }
      }

      .col-sm-2 {
         margin: 0 0 40px 0 !important;
         min-width: 33.333%;
         padding: 0 15px !important;
      }

      .social {
         display: flex;

         li {
            margin-right: 20px !important;
         }
      }

      .copyright {
         margin-top: 0;

         .justify-content-between {
            flex-wrap: wrap;
         }
      }
   }

   @media (max-width: 580px) {
      .col-sm-2 {
         min-width: 50%;
         max-width: 50%;
      }
     

      .parallax-logo {
         width: 100%;

         img {
            position: absolute;
            bottom: 0;
            width: 99%;
         }
      }
   }
`;

export default MyComponent;
