import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get} from "../../network/axiosServices";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    detail: [],
    detailLoading: false,
    detailError: '',
    page:[],
    pageLoading: false,
    pageError: '',
}

export const fetchProductPage = createAsyncThunk("fetchProductPage", (params) => {
    return get(params);
});
export const fetchProducts = createAsyncThunk("fetchProducts", (params) => {
    return get(params);
});

export const fetchProductDetails = createAsyncThunk("fetchProductDetails", (params) => {
    return get(params);
});


const postSlice = createSlice({
    name: 'products',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchProductPage.pending, (state) => {
            state.pageLoading = true
            state.page = []
            state.pageError = ''
        })
        builder.addCase(fetchProductPage.fulfilled, (state, action) => {
            state.pageLoading = false
            state.posts = action.payload
            state.pageError = ''
        })
        builder.addCase(fetchProductPage.rejected, (state, action) => {
            state.pageLoading = false
            state.page = []
            state.pageError = action.error
        })
        builder.addCase(fetchProducts.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''
        })
        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
        builder.addCase(fetchProductDetails.pending, (state, action) => {
            state.detailLoading = true
            state.detail = []
            state.detailError = ''
        })
        builder.addCase(fetchProductDetails.fulfilled, (state, action) => {
            state.detailLoading = false
            state.detail = action.payload
            state.detailError = ''
        })
        builder.addCase(fetchProductDetails.rejected, (state, action) => {
            state.detailLoading = false
            state.detail = []
            state.detailError = action.error
        })
    }
})


export default postSlice.reducer
