// reducers/loaderReducer.js
const initialState = {
    isLoading: false,
    loadingProgress: 0,
};

const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'START_LOADING':
            return {
                ...state,
                isLoading: true,
                loadingProgress: 0,
            };
        case 'SET_LOADING_PROGRESS':
            return {
                ...state,
                loadingProgress: action.payload,
            };
        case 'STOP_LOADING':
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};

export default loaderReducer;
