import React, {useEffect} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Button from "../Button";
import reactHtmlParser from "react-html-parser";
import Circle from "../Circle";
import {Img} from "../ParallaxBg";
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
// import {Img} from "../Img";
gsap.registerPlugin(ScrollTrigger);

const Counter = ({url, title, data, image}) => {

    // const image = data?.images?.list?.find((e)=> e?.bottom === "on")?.full_path;
    return (
        <>
            <StyledDetail className='pt-150 pb-150 parallax'>
                <Container>
                    <Row>
                        <Col md={6} className={'bottom-right'}>
                            <div className={'content-bottom'}>
                                <Circle/>
                                <h2>{reactHtmlParser(data?.section_data?.subtitle)}</h2>
                                {reactHtmlParser(data?.section_data?.description)}
                            </div>
                        </Col>
                        <Col md={{offset:1, span:5}} className={'bottom-left pb-150'}>
                            <div className={'image'}>
                                <Img src={image ? image : ''}/>
                                <div className={'image-top'}>
                                    <img src={data?.images?.list?.find((e)=> e?.top === "on")?.full_path} data-speed={0.9}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledDetail>
        </>

    );
};

const StyledDetail = styled.section`
  background-color: #FFFFFF;
  .bottom-left{
    position: relative;
    .image{
      //background-color: #1c7430;
      position: relative;
      padding-top: calc(600 / 500 * 100%);
      width: 100%;
      height: 100%;

      .global-image{
        overflow: hidden;
      }
      .image-top{
        img{
          position: absolute;
          width: 65%;
          height: auto;
          left: -70px;
          bottom: -160px;
        }
      }
    }
  }
  .bottom-right{
    .content-bottom{
      h2{
        color: #000000;
        margin-bottom: 40px;
      }
      h4{
        font-size: 24px;
        line-height: 20px;
        font-weight: 400;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 30px;
      }
      p{
        margin-bottom: 20px;
        font-size: 14px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }


  @media(max-width: 767px){
    .counter-left{
      padding-bottom: 0 !important;
      margin-right: 15px;
      .content{
        padding-bottom: 0;
        margin-right: 0;
        margin-bottom: 0;
        h5{
          font-size: 30px !important;
          line-height: 38px !important;
        }
      }

    }

    .counter-right{
      .content-top{
        margin-bottom: 180px;
        p{
          font-size: 18px;
          line-height: 24px;
          margin-top: 40px;
          margin-bottom: 40px;
        }
      }
    }

    .bottom-left{
      padding-bottom: 0 !important;
      .image{
        padding-top: calc(400 / 345 * 100%);
        width: 100%;

        .image-top{
          display: none;
        }
      }
    }
    
    .bottom-right{
      .content-bottom{
        margin-top: 0 !important;
      }
    }
  }

  .bottom-right{
    .content-bottom{
      margin-top: 70px;
      margin-bottom: 40px;
    }
  }

`;

export default React.memo(Counter);
