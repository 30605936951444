import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import DetailsBox from "../../components/product/DetailsBox";
import {ApiParam} from "../../api/network/apiParams";
import {fetchProductDetails} from "../../api/redux/product";

const MyComponent = () => {

    const dispath = useDispatch()
    const [isLoading, setIsLoading] = useState(true);
    let {slug} = useParams();

    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, []);

    useEffect(() => {
        let api_url = apiEndPoints.PRODUCT_DETAILS
        let param = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: slug,
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
            [ApiParam.FILE]: 'yes',
            [ApiParam.SPECIFICATION]: 'yes',
            [ApiParam.GALLERY]: 'yes',
            [ApiParam.VARIATION]: 'yes',
        }
        dispath(fetchProductDetails([api_url, param]))
    }, [dispath])


    let getPost = useSelector(store => store.productReducer)
    console.log('getPost', getPost)

    let detail = getPost?.detail?.data;

    return (

        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{getPost?.detail?.data?.product_data?.title ? getPost?.detail?.data?.product_data?.title+' | Probriddhi Apparels Ltd.' : 'Probriddhi Apparels Ltd.'}</title>
                {
                    getPost?.detail?.data?.product_data?.meta_description &&
                    <meta name="description" content={getPost?.detail?.data?.product_data?.meta_description}/>

                }
                {
                    getPost?.detail?.data?.product_data?.og_title &&
                    <meta property="og:title" content={getPost?.detail?.data?.product_data?.og_title}/>

                }
                {
                    getPost?.detail?.data?.product_data?.og_description &&
                    <meta property="og:description" content={getPost?.detail?.data?.product_data?.og_description}/>

                }
            </Helmet>

            <StyledComponent>
                <DetailsBox data={detail}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
