import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import globalReducer from '../redux/global'
import homeReducer from  '../redux/home'
import aboutReducer from '../redux/about'
import productReducer from '../redux/product'
import factoryReducer from "../redux/factory"
import sustainabilityReducer from "../redux/sustainability"
import newsReducer from "../redux/news"
import careerReducer from "../redux/career"
import contactReducer from "../redux/contact"
import socialReducer from "../redux/social"
import loaderReducer from "../redux/loader"
import rootReducer from "../redux/root"

const store = configureStore({
    reducer: {
        postReducer,
        globalReducer,
        homeReducer,
        aboutReducer,
        productReducer,
        factoryReducer,
        sustainabilityReducer,
        newsReducer,
        contactReducer,
        careerReducer,
        socialReducer,
        loaderReducer,
        rootReducer

    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

