import React, { useContext } from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';

import TransitionContext from '../context/TransitionContext';

const TransitionComponent = ({ children }) => {
  const location = useLocation();
  const { toggleCompleted } = useContext(TransitionContext);
  return (
    <SwitchTransition>
      <Transition
        key={location.pathname}
        timeout={500}
        onEnter={(node) => {
          toggleCompleted(false);
          gsap.set(node, { autoAlpha: 0 });
          gsap.set(document?.querySelector('.InnerBanner img'), {autoAlpha:0})
          gsap.to(node, {
            autoAlpha: 1,
            duration: 1,
            onComplete: () => toggleCompleted(true),
            ease: "power1.out",
          });
          gsap.to(document?.querySelector('.InnerBanner img'), {
            autoAlpha: 1,
            duration: 1,
            onComplete: () => toggleCompleted(true),
            ease: "power1.out",
          });
        }}
        onExit={(node) => {
          gsap.to(node, {
            autoAlpha: 0,
            duration: 0.4,
            ease: "power1.out",
          });
          gsap.to(document?.querySelector('.InnerBanner img'), {
            autoAlpha: 0,
            duration: 0.4,
            ease: "power1.out",
          });
        }}
      >
        {children}
      </Transition>

    </SwitchTransition>
  );
};

export default TransitionComponent;
