import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import NewsBox from "../NewsBox";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                <Row>
                    {
                        data && data?.length>0 &&
                        data?.map((e,i)=>{
                            return(
                                <Col md={6} key={i}>
                                    <NewsBox
                                        title={e?.data?.title}
                                        img={e?.images?.list?.find(e => e?.thumb === 'on')?.full_path}
                                        date={e?.data?.date}
                                        type={e?.data?.category_title}
                                        url={`/news/${e?.data?.slug}`}
                                    />
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

    .col-md-6{
      margin-bottom: 40px;
      
      &:last-child{
        margin-bottom: 0;
      }
    }
    
`;

export default MyComponent;
