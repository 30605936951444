import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import {Img} from "../Img";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {careerForm} from "../../api/redux/career";
import Circle from "../Circle";
import {ImageParallax} from "../ImageParallax";
import {contactForm} from "../../api/redux/contact";

const MyComponent = ({data}) => {

    const [uploadText, setUploadText] = useState('Upload CV*')



    // handleUpload function

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.careerReducer);

    // Move useForm hook call here
    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };

    let api_services = apiEndPoints.POST_FORM;

    // const onSubmit = (e) => {
    //
    //     let api_services = apiEndPoints.POST_FORM;
    //
    //     var formData = new FormData();
    //     formData.append('full_name', e?.name);
    //     formData.append('email', e?.email);
    //     formData.append('phone', e?.phone);
    //     formData.append('message', e?.message);
    //     formData.append('file', cv);
    //     formData.append('form_id', 'career-form');
    //
    //     if (e.email !== '' && e.name !== '' && e.phone !== '' && e?.message !== '') {
    //         dispatch(careerForm([api_services, formData]));
    //         // success('Successfully Submitted')
    //         reset();
    //     }
    // };
    // let count = 0;
    // const onError = (errors) => {
    //     Object.values(errors).forEach((error) => {
    //         count++;
    //     });
    //     if (count > 0) {
    //         toast.error('please fill out the correct inputs');
    //     }
    //     count = 0;
    // };
    // useEffect(() => {
    //     if (responseData && responseData?.error !== '') {
    //         error(responseData?.error?.message)
    //     }
    //     if (responseData && responseData?.success !== '') {
    //         success(responseData?.success)
    //     }
    //     reset();
    //
    // }, [responseData])


    const onSubmit = async (e) => {
        try {

            var formData = new FormData();
            formData.append('full_name', e?.name);
            formData.append('email', e?.email);
            formData.append('phone', e?.phone);
            formData.append('message', e?.message);
            formData.append('file', cv);
            formData.append('form_id', 'career-form');

            const response = await dispatch(careerForm([api_services, formData]));

            if (response && response.payload?.status === 1) {
                success(response.payload?.message);
                reset(); // Reset form fields
                setUploadText('Upload CV*');
            } else if (response) {
                error(response?.payload?.response?.data?.message);
                reset(); // Reset form fields
                setUploadText('Upload CV*');
            } else {
                error('Failed to submit form. Please try again later.');
            }
        }catch (error) {
            error('Failed to submit form. Please try again later.');
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };

    const [cv, setCv] = useState(null)
    const cvRef = useRef()

    const handleUpload = (event) => {
        const selectedFile = event.target.files[0];

        setCv(selectedFile);

        if (selectedFile) {
            const fileName = selectedFile.name;
            setUploadText(fileName);
            // You might want to remove or adjust this line depending on your styling
            document.querySelector('.gph_upload').classList.add('hide');
        }
    };



    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                <Row>
                    <Col md={6} className={'left box-down'}>
                        <Circle/>
                        <h2>Apply Now</h2>
                        <Form >
                            <Form.Group className={'from-group'}>
                                <Form.Control
                                    className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("name", {
                                        required: 'Name is required',
                                        pattern: {
                                            message: 'Name must contain only letters',
                                        },

                                    })}
                                    type="text" placeholder="Enter your full name*"/>
                            </Form.Group>
                            <Form.Group className={'from-group d-flex'}>
                                <Form.Control
                                    className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("phone", {
                                        required: {
                                            value: true, message: 'please enter your phone first'
                                        }, pattern: {
                                            value: /^01[0-9]{9}$/,
                                            message: 'please enter a valid 11 digit phone number'
                                        }
                                    })}
                                    type="number" placeholder="Enter your phone number*"/>
                            </Form.Group>
                            <Form.Group className={'from-group d-flex'}>
                                <Form.Control
                                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("email", {
                                        required: {
                                            value: true, message: 'please enter your email'
                                        }, pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'please enter a valid email address'
                                        }
                                    })}
                                    type="email" placeholder="Enter your email address*"/>
                            </Form.Group>
                            <textarea
                                className={formState?.errors?.email?.message ? ' form-control-lg textarea form-control' : 'textarea form-control form-control-lg'}
                                {...register('message', {
                                    required: {
                                        value:true,
                                        message: 'please enter your cover letter',
                                    },
                                })}
                                placeholder={'Enter your cover letter*'}/>

                           <div className={'buttons d-flex'}>
                               <Form.Group className={'upload'}>
                                   <Form.Control
                                       ref={cvRef}
                                       onChange={handleUpload} // Change from onInput to onChange
                                       className={'gph_upload'}
                                       text={uploadText}
                                       type="file"
                                       accept=".pdf"

                                   />
                               </Form.Group>

                               <div className="button" onClick={handleSubmit(onSubmit, onError)}>
                                   <p>
                                       Submit
                                   </p>
                               </div>
                           </div>

                        </Form>

                    </Col>
                    <Col md={6} className={'right'}>
                        <div className={'wrapper'}>
                            <ImageParallax src={data?.images?.list[0]?.full_path}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  
  
  .container{
    height: 100%;
  }
 .left{
   h2{
     color: #000000;
     margin-bottom: 40px;
   }
   form{
     //padding: 60px 50px;
     
   }
   .from-group {
     margin-bottom: 30px;
     gap: 30px;
     &:nth-last-child(1) {
       margin-bottom: 0;
     }
   }
   .form-control {
     background-color: transparent;
     border: none;
     border-bottom: 1px solid rgb(112 112 112 / 40%);
     //border-radius: 25px;
     padding-left: 0;
     height: 45px;
     //margin-bottom: 25px;
     color: #373737;
     text-indent: 15px;
     font-size: 14px;
     &::placeholder {
       font-size: 14px;
       line-height: 18px;
       color: #373737;
     }
     @media(max-width: 767px){
       font-size: 16px;
       &::placeholder{
         font-size: 16px;
       }
     }
   }
   textarea {
     width: 100%;
     max-width: 100%;
     min-width: 100%;
     background-color: transparent;
     border-bottom: 1px solid rgb(112 112 112 / 40%);
     //border-radius: 15px;
     height: 200px;
     max-height: 80px;
     min-height: 80px;
     color: #373737;
     box-shadow: none;
     outline: none;
     padding-left: 15px;

     &::placeholder {
       font-size: 14px;
       color: #373737;

     }
   }
   .button{
     margin-top: 30px;
     width: 50%;
     height: 50px;
     background-color: #ED1A3B;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 25px;
     cursor: pointer;
     p{
       color: #ffffff;
     }
   }
 }

  .buttons{
    gap: 30px
  }
  
  .right{
    padding-left: 70px;
    .wrapper{
      position: relative;
      padding-top: 100%;
      overflow: hidden;
    }
  }

  .form-control-lg{
    padding: 0 0 15px;
  }
  
  @media(max-width: 767px){
    .row{
      flex-direction: column-reverse !important;
      .right{
        padding-left: 15px;
        margin-bottom: 60px;
      }
    }
  }
`;

export default MyComponent;
