import React, {useEffect} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Button from "./Button";
import reactHtmlParser from "react-html-parser";
import {Img} from "./ParallaxBg";
import Circle from "./Circle";
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useSelector} from "react-redux";
gsap.registerPlugin(ScrollTrigger);


const Counter = ({url, title, data, subtitle, desc, bigImage, smallImage}) => {

    useEffect(() => {
        gsap.to('.image-top', {
            yPercent: -75,
            ease: 'power1.inOut', // Adjust the ease for smoother animation
            scrollTrigger: {
                trigger: '.bottom-left',
                start: 'top top',
                end: 'bottom bottom',
                scrub: 1, // Adjust the scrub value for smoother animation
            },
        });
    }, [smallImage, bigImage]);


    return (
        <>
            <StyledDetail className='pt-150 pb-150' id="overview">
                <Container>
                    <Row>
                        <Col md={6} className={'bottom-left pb-150'}>
                            <div className={'image'} >
                                <Img speed={15} src={bigImage}/>
                                <div className={'image-top'}>
                                    <img src={smallImage}/>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} className={'bottom-right'}>
                            <div className={'content-bottom'}>
                                <Circle/>
                                <h2>{title}</h2>
                                <h3>{subtitle}</h3>
                                {desc}
                                {
                                    url ?
                                        <Button text={'Read More'} src={'/about'} margin={'60px 0 0 0'}/>
                                        : ''
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledDetail>
        </>

    );
};

const StyledDetail = styled.section`
  background-color: #FFFFFF;
  .bottom-left{
    position: relative;
    overflow: hidden;
    padding-right: 70px;
    .image{
      //background-color: #1c7430;
      position: relative;
      padding-top: calc(600 / 500 * 100%);
      width: auto;
      height: 100%;
      .global-image{
        overflow: hidden;
      }
      .image-top{
        img{
          position: absolute;
          width: 65%;
          height: auto;
          right: -70px;
          bottom: -170px;
        }
      }
    }
  }
  .bottom-right{
    .content-bottom{
      margin-left: 70px;
      h2{
        margin-bottom: 40px;
        color: #000000;
      }
      h3{
        font-weight: 300;
        text-transform: Capitalize;
        color: #000000;
        margin-bottom: 30px;
      }
      p{
        margin-bottom: 20px;
        font-size: 14px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      @media(max-width: 767px){
        margin-left: 0;
      }
    }
  }


  @media(max-width: 767px){
    .counter-left{
      padding-bottom: 0 !important;
      margin-right: 15px;
      .content{
        padding-bottom: 0;
        margin-right: 0;
        margin-bottom: 0;
        h5{
          font-size: 30px !important;
          line-height: 38px !important;
        }
      }

    }

    .counter-right{
      .content-top{
        margin-bottom: 180px;
        p{
          font-size: 18px;
          line-height: 24px;
          margin-top: 40px;
          margin-bottom: 40px;
        }
      }
    }

    .bottom-left{
      padding-bottom: 0 !important;
      padding-right: 15px;
      .image{
        padding-top: calc(400 / 345 * 100%);
        width: 100%;

        .image-top{
          display: none;
        }
      }
    }
  }

  .bottom-right{
    .content-bottom{
      margin-top: 70px;
    }
  }

`;

export default React.memo(Counter);
