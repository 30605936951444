import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../ParallaxBg";
import {hover} from "../../styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";
import {CSSPlugin, gsap, TimelineLite, Power2} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)
const Collection = ({data}) => {
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        setBackgroundImage(data?.images?.list?.find((e)=>e?.background === "on")?.full_path);
    }, [data]);


    console.log('collection', data)


   
    return (
        <StyledComponent className={'reel-values pt-150 pb-150'} bg={backgroundImage}>
            <Container>
                <Row>
                    <Col sm={2} className={'reel-values__title'}>
                        <h2 className={'desktop'}>{reactHtmlParser(data?.section_data?.subtitle)}</h2>
                        <h2 className={'mobile'}>{reactHtmlParser(data?.section_data?.subtitle)}</h2>
                    </Col>

                    <Col sm={9} className={'reel-values__boxes'}>
                        <Row>
                            {
                                data?.posts?.list && data?.posts?.list?.length>0 &&
                                data?.posts?.list?.map((e,i)=>{
                                    return(
                                        <Col sm={6}>
                                            <Link to={e?.data?.short_desc}>
                                                <div className="reel-values__boxes__img boxr">

                                                    <Img speed={15} src={e?.images?.[0]?.full_path}/>
                                                    <h3>{reactHtmlParser(e?.data?.subtitle)}</h3>
                                                    <h4 className={'box'}>{i+1}</h4>
                                                </div>
                                            </Link>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  overflow: hidden;
  background-image: url("${props => props.bg}");
  background-size: auto;
  background-repeat: repeat;

  .reel-values__title {
    position: relative;

    h2 {
      text-transform: capitalize;
      color: #FFFDFB;
      white-space: nowrap;
      position: absolute;
      left: 70px;
      top: 50%;
      //bottom: 0;
      height: fit-content;
      //margin: auto;
      transform: rotate(-90deg) translateY(-130px);
    }
  }


  .reel-values__boxes {
    .col-sm-6 {
      margin-bottom: 80px;

      &:nth-of-type(odd) {
        padding-right: 70px;
      }

      &:nth-of-type(even) {
        padding-top: 150px;
        padding-left: 70px;
      }

      &:nth-last-child(2) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__img {
      padding-top: 100%;
      position: relative;

      h3 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
      }

      h4 {
        font-size: 120px;
        line-height: 70px;
        font-weight: 300;
        color: #ffffff;
        position: absolute;
        top: 0;
        left: 40px;
        z-index: 2;
        transform: translateY(-50%);
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #170C3D;
      margin-top: 20px;
    }
  }

  @media (min-width: 1600px) {
    .reel-values__title h3 {
      transform: rotate(-90deg) translateY(22%);
    }
  }

  @media (max-width: 991px) {
    .reel-values__boxes {
      .col-sm-6 {
        margin-bottom: 40px;

        &:nth-of-type(odd) {
          padding-right: 30px;
        }

        &:nth-of-type(even) {
          padding-top: 100px;
          padding-left: 30px;
        }
      }

      &__img h3 {
        font-size: 25px;
        line-height: 28px;
        //left: 15px;
        //right: 15px;
      }
    }
  }

  @media (min-width: 768px) {
    .mobile {
      display: none;
    }
  }

  @media (max-width: 991px) {
    .reel-values__title h3 {
      transform: rotate(-90deg) translateY(50px);
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 100px !important;
    .desktop {
      display: none;
    }

    .mobile {
      display: unset;
    }

    .reel-values__title {
      min-width: 100%;
      margin-bottom: 130px;

      h2 {
        transform: none;
        white-space: normal;
        position: relative;
        left: 0;
        right: auto;
        width: auto;
      }
    }

    .reel-values__boxes {
      min-width: 100%;

      .col-sm-6 {
        padding-right: 15px !important;
        padding-left: 15px !important;

        &:nth-of-type(even) {
          padding-top: 0;
        }

        &:last-child {
          margin-top: 120px;
        }
      }

      &__img {
        h3 {
        }
      }
    }

  }

  @media (max-width: 575px) {
    //padding-bottom: 0 !important;
    .reel-values__boxes {
      .col-sm-6 {
        margin-bottom: 60px;
      }

      &__img {
        h4 {
          font-size: 80px;
          line-height: 70px;
          left: 15px;
        }

      }


      p {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

`;

export default React.memo(Collection);
