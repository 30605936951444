import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import HrPhilosophy from "../../components/career/HrPhilosophy";
import Benefits from "../../components/career/Benefits";
import Form from "../../components/career/Form";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchCareer} from "../../api/redux/career";
import reactHtmlParser from "react-html-parser";

const MyComponent = () => {

    const dispath = useDispatch()
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, []);

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS
        let param = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: 'career',
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
            [ApiParam.GALLERY]: 'yes',
        }
        dispath(fetchCareer([api_url, param]))
    }, [dispath])


    let getPost = useSelector(store => store.careerReducer)


    //refactor
    let page_data = getPost?.posts?.data?.page_data;
    let banner = getPost?.posts?.data?.sections?.find(e => e?.section_data?.slug === 'career-banner');
    let hr = getPost?.posts?.data?.sections?.find(e => e?.section_data?.slug === 'hr-philosophy');
    let benefits = getPost?.posts?.data?.sections?.find(e => e?.section_data?.slug === 'benefits');
    let form = getPost?.posts?.data?.sections?.find(e => e?.section_data?.slug === 'career-form');

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? `${page_data?.meta_title} | Probridhi Apparels Ltd.` : 'Probridhi Apparels Ltd.'}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    title={reactHtmlParser(banner?.section_data?.subtitle)}
                    img={banner?.images?.list?.[0]?.full_path}/>
                <HrPhilosophy data={hr}/>
                <Benefits data={benefits}/>
                <Form data={form}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
