import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import {Link} from "react-router-dom";


const MyComponent = ({img, title, url}) => {
    return (
        <StyledComponent>
            <Link to={url ? url : ''}>
                <div className="directors_single">
                    <div className="directors_single__img_wrapper">
                        <Img src={img} alt=""/>
                    </div>
                </div>
                <div className="directors_single-content">
                    <h4>{title}</h4>
                </div>
            </Link>
        </StyledComponent>
    );
};

const StyledComponent = styled.div`
  .directors_single {
    padding: 25px 20px;
    background: #ffffff;
    cursor: pointer;
    &__img_wrapper {
      position: relative;
      padding-top: calc(300 / 230 * 100%);
      overflow: hidden;

      img {
        transition: 0.7s all ease;
        transform: scale(1.01);
      }
    }
    

    &:hover {
      .directors_single__img_wrapper {
        img {
          transform: scale(1.04);

        }
      }
    }
  }
  .directors_single-content{
    padding-top: 30px;
    background-color: #E9E9E9;
    h4{
      color: #000000;
    }
  }
`;

export default MyComponent;
