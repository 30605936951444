import {Route, Switch, useHistory, useLocation, useNavigate, useParams} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {ImageReveal} from "./components/animations/ImageReveal";
import {Parallax, ParallaxImg} from "./components/animations/Parallax";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {SplitUp} from "./components/animations/TextAnimation";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import Router from "./router/Router";
import PageLoader from "./components/preload/PageLoader";
import {useDispatch, useSelector} from "react-redux";
import {Loading} from "./components/Loading";


function App() {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    const location = useLocation();
    let getPost = useSelector(state => state)
    // const store = useSelector(store=>store)



    //canonical link generate
    useEffect(() => {
        // Get the current URL from the router's full path
        const currentUrl = window.location.origin + location.pathname + location.search;

        // Set the canonical link dynamically
        const link = document.createElement('link');
        link.rel = 'canonical';
        link.href = currentUrl;
        document.head.appendChild(link);

        // Clean up the link when the component unmounts
        return () => {
            document.head.removeChild(link);
        };
    }, [location.pathname, location.search]);

    // smooth scroll init
    const el = useRef();
    useLayoutEffect(() => {
        let smoother = ScrollSmoother.create({
            smooth: window.innerWidth > 767 ? 2 : '', // how long (in seconds) it takes to "catch up" to the native scroll position
            effects: window.innerWidth > 767 ? true : false, // looks for data-speed and data-lag attributes on elements
            // speed: 2
        });
        return () => {
            smoother.kill();
        };
    }, [location.pathname, getPost]);

    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === '/') {
            document.body.classList.add('in-home-page')
        } else {
            document.body.classList.remove('in-home-page')
        }

        window.scroll(0, 0)
    }, [location.pathname])

    //resize observer error
    // window.ResizeObserver = undefined;

    const [textColor, setTextColor] = useState('#ffffff')
    const [menuColor, setMenuColor] = useState('transparent');
    const [logoColor, setLogoColor] = useState('#ED1A3B');
    const [borderColor, setBorderColor] = useState('rgb(255 255 255 / 15%)')

    const { slug } = useParams();
    const navigate = useNavigate();



    useEffect(() => {
        if (
            // history?.location?.pathname.startsWith(`/`)  ||
            location?.pathname.startsWith(`/product/`) ||
            location?.pathname.startsWith(`/news/`) ||
            location.pathname === '/'
        ) {
            setMenuColor('#ffffff');
            setTextColor('#000000');
            setLogoColor('#ED1A3B');
            setBorderColor('rgb(99 100 102 / 15%)')
        } else {
            setMenuColor('transparent');
            setTextColor('#ffffff');
            setLogoColor('#ffffff');
            setBorderColor('rgb(255 255 255 / 15%)')
        }
    }, [location.pathname, slug]);


    // animation functions
    ImageReveal()
    Parallax()
    ParallaxImg()
    SplitUp()




    return (
        <>
            {getPost?.contactReducer?.loading && <Loading/>}
            {getPost?.careerReducer?.loading && <Loading/>}
            <GlobalStyle/>
            <ToastContainer position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>
            <Menu color={menuColor} textColor={textColor} logoColor={logoColor} borderColor={borderColor}/>
            {
                location.pathname === "/" &&
                <PageLoader />
            }
            <div className="App" ref={el} id="smooth-wrapper">
                <div id="smooth-content">
                    <Router/>
                    <Footer/>
                </div>
            </div>

        </>


    );
}

export default App;
