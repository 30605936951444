import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams, Link, useLocation, useNavigate,
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail, fetchPosts} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import InnerBanner from "../../components/InnerBanner";
import ProductList from "../../components/product/ProductList";
import {ApiParam} from "../../api/network/apiParams";
import {fetchProducts} from "../../api/redux/product";

const MyComponent = () => {

    const dispath = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, []);

    const location = useLocation();
    const navigate = useNavigate();

    // const categoryParam = new URLSearchParams(location?.search).get('category_id');
    // const [selectedCat, setSelectedCat] = useState(categoryParam || '');


    useEffect(() => {
        let api_url = apiEndPoints.PRODUCT_DATA
        let param = {
            [ApiParam.CATEGORY_ID]: '',
            [ApiParam.FILE]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
            [ApiParam.SPECIFICATION]: 'yes',
            [ApiParam.GALLERY]: 'yes',
            [ApiParam.VARIATION]: 'yes',
        }
        dispath(fetchProducts([api_url, param]))
    }, [ dispath])


    let getPost = useSelector(store => store.productReducer)


    //refactor
    let page_data = getPost?.posts?.page_data?.page_data;
    let banner = getPost?.posts?.page_data?.sections[0];
    let products = getPost?.posts?.product_list;

    return (
        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <title>{`${page_data?.meta_title ? `${page_data?.meta_title} | Probridhi Apparels Ltd.` : 'Probridhi Apparels Ltd'}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                <InnerBanner title={banner?.section_data?.subtitle} img={banner?.images?.list[0]?.full_path }/>
                <ProductList
                    data={getPost?.posts}
                />
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
