import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import Overview from "../../components/factory/Overview";
import Strength from "../../components/factory/Strength";
import Gallery from "../../components/factory/Gallery";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchFactory} from "../../api/redux/factory";
import reactHtmlParser from "react-html-parser";

const MyComponent = () => {

    const dispath = useDispatch()
    const [isLoading, setIsLoading] = useState(true);

    // Simulate loading your content (e.g., data fetching)
    useEffect(() => {
        // Simulate a delay (e.g., fetching data from an API)
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false when content is ready
        }, 1500); // Adjust the delay duration as needed
    }, []);

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS
        let param = {
            [ApiParam.TYPE]: 'slug',
            [ApiParam.VALUE]: 'factory-profile',
            [ApiParam.GET_SECTION]: 'yes',
            [ApiParam.IMAGE]: 'yes',
            [ApiParam.POST]: 'yes',
            [ApiParam.GALLERY]: 'yes',
        }
        dispath(fetchFactory([api_url, param]))
    }, [dispath])


    let getPost = useSelector(store => store.factoryReducer)

    //refactor
    let page_data = getPost?.posts?.data?.page_data;
    let banner = getPost?.posts?.data?.sections?.find(e => e?.section_data?.slug === 'factory-profile-banner');
    let overview = getPost?.posts?.data?.sections?.find(e => e?.section_data?.slug === 'factory-profile-overview');
    let strength = getPost?.posts?.data?.sections?.find(e => e?.section_data?.slug === 'our-strength');
    let gallery = getPost?.posts?.data?.sections?.find(e => e?.section_data?.slug === 'gallery');

    console.log('data', overview)

    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${page_data?.meta_title ? `${page_data?.meta_title} | Probridhi Apparels Ltd.` : 'Prpbridhi Apparels Ltd'}`}</title>

                {
                    page_data?.meta_desc &&
                    <meta name="description" content={page_data?.meta_desc}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_desc &&
                    <meta property="og:description" content={page_data?.og_desc}/>

                }
            </Helmet>

            <StyledComponent>
                <InnerBanner
                    title={reactHtmlParser(banner?.section_data?.subtitle)}
                    img={banner?.images?.list?.[0]?.full_path}/>
                <Overview
                    data={overview}
                    image={overview?.images?.list?.find((e)=> e?.bottom === "on")?.full_path}
                />
                <Strength data={strength}/>
                <Gallery data={gallery}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
