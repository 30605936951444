import {createGlobalStyle, css} from 'styled-components';
import {hover, light, text, title} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

  html {
    scrollbar-color: ${hover} #FEF8F4;
    scrollbar-width: thin;
  }

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }


  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/HelveticaNeue.woff2') format('woff2'),
    url('/fonts/HelveticaNeue.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/HelveticaNeue-Bold.woff2') format('woff2'),
    url('/fonts/HelveticaNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/HelveticaNeue-Light.woff2') format('woff2'),
    url('/fonts/HelveticaNeue-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/HelveticaNeue-Medium.woff2') format('woff2'),
    url('/fonts/HelveticaNeue-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Helvetica Neue';
    src: url('/fonts/HelveticaNeue-Thin.woff2') format('woff2'),
    url('/fonts/HelveticaNeue-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  


  body {
    font-family: 'Helvetica Neue', Arial, freesans, sans-serif !important;
    font-style: normal;
    margin: 0;
    color: ${text};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    line-height: 24px;

    transition: 1s ease-in-out;
    &.menu-open{
      overflow: hidden;
      //transition: 1s ease-in-out;
    }
  }

  h1{
    font-family: ${title};
    font-size: 60px;
    line-height: 70px;
    font-weight: ${light};
    margin: 0;
    // Desktop
    @media (min-width: 1550px) {
      font-size: 60px;
      line-height: 70px;
    }
    // Primary/Laptop
    @media (max-width: 1549px) {
      font-size: 60px;
      line-height: 70px;
    }
    // Mobile
    @media (max-width: 767px) {
      font-size: 40px;
      line-height: 70px;
    }
  }

  h2{
    font-family: ${title};
    font-size: 40px;
    line-height: 50px;
    font-weight: ${light};
    margin: 0;
    // Desktop
    @media (min-width: 1550px) {
      font-size: 48px;
      line-height: 60px;
    }
    // Primary/Laptop
    @media (max-width: 1549px) {
      font-size: 40px;
      line-height: 50px;
    }
    // Mobile
    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 40px;
    }
  }
  h3{
    font-family: ${title};
    font-size: 24px;
    line-height: 36px;
    font-weight:400;
    margin: 0;
    // Desktop
    @media (min-width: 1550px) {
      font-size: 28px;
      line-height: 36px;
    }
    // Primary/Laptop
    @media (max-width: 1549px) {
      font-size: 24px;
      line-height: 36px;
    }
    // Mobile
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 36px;
    }
  }

  h4{
    font-family: ${title};
    font-size: 18px;
    line-height: 24px;
    font-weight:${light};
    margin: 0;
    // Desktop
    @media (min-width: 1550px) {
      font-size: 20px;
      line-height: 24px;
    }
    // Primary/Laptop
    @media (max-width: 1549px) {
      font-size: 18px;
      line-height: 24px;
    }
    // Mobile
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  h5{
    font-family: ${title};
    font-size: 14px;
    line-height: 21px;
    font-weight:400;
    margin: 0;
    // Desktop
    @media (min-width: 1550px) {
      font-size: 16px;
      line-height: 21px;
    }
    // Primary/Laptop
    @media (max-width: 1549px) {
      font-size: 14px;
      line-height: 21px;
    }
    // Mobile
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  h6{
    font-family: ${title};
    font-size: 12px;
    line-height: 18px;
    font-weight:400;
    margin: 0;
    // Desktop
    @media (min-width: 1550px) {
      font-size: 14px;
      line-height: 18px;
    }
    // Primary/Laptop
    @media (max-width: 1549px) {
      font-size: 12px;
      line-height: 18px;
    }
    // Mobile
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
  
  
  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  p, a {
    color: ${text};
    font-weight: 400;
    margin: 0;
  }

 

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  
  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-160 {
    padding-top: 160px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-160 {
    padding-bottom: 160px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-150 {
    padding-top: 150px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-150 {
    padding-bottom: 150px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }
  
  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }

  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;

    span {
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: ${hover};
      transition: all .5s ease;
      border-radius: 19px;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
    }
  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //menu fixed
  .scroll-down .menu-desktop {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up .menu-desktop {
    background-color: #fff;

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #191818;
      }

      p {
        color: #191818;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #191818;

      &:hover {
        color: #191818 !important;
      }

      &:after, &:before {
        box-shadow: 0 0 0 1px #191818;
      }
    }
  }

  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  .Toastify__toast-container {
    z-index: 99999999;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }

  .page-loader {
    position: fixed;
    background-color: rgb(36, 24, 67);
    width: 100vw;
    z-index: 999999999;
    overflow: hidden;
    //opacity: 0;
    //height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //left: 0; //width: 0;
    //transition: all 2.6s ease;
    //.anim-logo {
    //  width: 130px;
    //  overflow: hidden;
    //  height: fit-content;
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  top: 0;
    //  bottom: 0;
    //  opacity: 0;
    //  margin: auto;
    //  z-index: 2;
    //
    //  img {
    //    height: 55px;
    //  }
    //}

    //.hide-logo {
    //  background-color: #010A1A;
    //  width: 50%;
    //  height: 100vh;
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  margin: auto;
    //  position: absolute;
    //  z-index: 999;
    //}

    .pre-loader__img {
      //position: fixed;
      height: 100px;
      width: 100px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(36, 24, 67);
      z-index: 99999999;

      //flex: 1;
      margin: auto;
      opacity: 0;

      img {
        display: block;
      }

      .top {
        height: 8px;
      }

      .bottom {
        height: 30px;
        margin-top: 5px;
        animation: flip 1s linear infinite;
      }

      @keyframes flip {
        0% {
          transform: rotateY(0deg)
        }
        100% {
          transform: rotateY(180deg)
        }
      }
    }

  }

  //------------------------animation
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .split-text {
    word-break: break-all !important;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }


  //page transition
  // .page-transition {
  //   position: fixed;
  //   height: 100%;
  //   width: 100%;
  //   top: 0;
  //   left: 0;
  //   background-color: ${hover};
  //   z-index: 999999999;
  //   display: none;
  //   opacity: 0;
  //   //display: flex;
  //   align-items: center;
  //   justify-content: center;
  //
  //   .logo {
  //     height: 80px;
  //     opacity: 0;
  //   }
  // }

  .menu-open {
    //.ham{
    //  p{
    //    display: none;
    //    opacity: 0;
    //  }
    //}
    .close{
      gap: 10px;
      p{
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
    //.contact{
    //  display: none;
    //  opacity: 0;
    //}
    //.main-menu__logo{
    //  display: none;
    //  opacity: 0;
    //}
  }
  
  //modal video
  .gph_modal {
    background: transparent;
    overflow: hidden !important;
    z-index: 9999;

    .hover {
      height: 56px;
      width: 56px;
      background-color: #fff !important;
      border-radius: 50%;

      svg {
        //font-size: 17px;
      }
    }

    .modal-body {
      padding-top: 60px !important;
      @media (max-width: 1200px) {
        .col-lg-5 {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }
      @media (max-width: 767px) {
        .col-lg-5, .col-sm-12 {
          padding-left: 0px !important;
          padding-right: 0px !important;
        }

        padding-left: 0px;
        padding-right: 0px;
      }
    }

    .modal-dialog {
      margin: 0;
      width: 100%;
      height: 100%;
      //background: #F4F4F4;
      max-width: 100%;

      .btn-close {
        display: none;
      }

      .modal-header {
        padding: 0;
        margin-bottom: 0;
        background: #F9F9F9;
        border-bottom: transparent;
        position: relative;

        .h4 {
          color: #222222;
          font-weight: 500;
        }

        .header_wrap {
          display: flex;
          justify-content: space-between;
          align-items: start;
          padding: 80px 0px 20px;
          border-bottom: 1px solid #404040 !important;
          margin: 0px 15px;
          position: relative;

          @media (max-width: 1499px) {
            margin: 0px 0px;

          }


          .btn-circle {
            position: absolute;
            right: -100px;
            top: 20px;
            height: 56px;
            width: 56px;
            border-radius: 50%;
            background-color: ${text};
            @media (max-width: 767px) {
              right: 15px;
              top: 20px;
            }

            svg line {
              transition: .4s ease;
            }

            &:after {
              background-color: ${hover};
            }

            &:hover {
              svg {
                line {
                  stroke: #ffffff;
                }
              }
            }

            //&:hover {
            //  svg {
            //    stroke: red; /* Replace 'red' with your desired color */
            //  }
            //}

            //&:hover {
            //  svg {
            //    stroke: white;
            //  }
            //
            //  .btn-circle:after {
            //    top: 0;
            //  }
            //}
          }


          h3 {
            font-size: 32px;
            line-height: 36px;
            font-weight: 600;
            //margin-left: 16px;
            margin-right: 4px;

            @media (max-width: 767px) {
              margin-top: 44px;
              margin-left: 15px;
            }
            @media (min-width: 1550px) {
              font-size: 40px;
              line-height: 56px;
            }
            @media (max-width: 767px) {
              font-size: 24px;
              line-height: 32px;
            }
          }


          .close_button {
            cursor: pointer;
            margin-right: 16px;
            position: absolute;
            top: 20px;
            right: 20px;

            &:after {
              content: '';
              position: absolute;
              height: 0%;
              width: 0%;
              border-radius: 50%;
              color: #FFFFFF;
              background-color: red;
                // background-color: ${hover};
              opacity: 0;
              transition: all .4s ease;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              margin: auto;

            }

            &:hover:after {
              height: 100%;
              width: 100%;
              opacity: 1;

            }

            &:hover {
              line {
                stroke: #FFF;
              }
            }

            //&:hover {
            //  svg {
            //    g {
            //      g {
            //        path {
            //          stroke: #FFFFFF !important;
            //        }
            //      }
            //    }
            //  }
            //
            //}
          }

        }

      }

      .custombar {
        position: relative;

        &:after {
          z-index: 1;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100px;
          background: transparent;
          //background: linear-gradient(to bottom, rgba(244, 244, 244, 0) 0%,
          //rgba(244, 244, 244, 1) 100%);
          //@media (max-width: 767px) {
          //  background: linear-gradient(to bottom, rgba(244, 244, 244, 0) 0%,
          //  rgba(244, 244, 244, 0.5) 100%);
          //}
        }
      }

      .modal-content {
        height: 100vh;
        padding: 0;
        //background: #F9F9F9;
        border: none;

        .left_col {
          padding: 60px 0px 60px;

          .custom-list {
            padding: 0;

            li {

              position: relative;
              padding-left: 20px;
              padding-top: 20px;

              &:not(:last-child) {
                border-bottom: 1px solid rgba(217, 217, 217, 0.5);
                padding-bottom: 20px;

              }

              &:before {
                content: '';
                position: absolute;
                top: 30px;
                left: 0;
                //transform: translate(-50%,-50%);
                margin-top: -5px;
                width: 10px;
                height: 10px;
                box-sizing: content-box;
                background-color: #C1C1C1;
              }
            }

          }

          h3 {
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            color: #404040;

            &:not(:first-child) {
              margin-top: 40px;
            }
          }

          p {
            font-weight: 400;
            color: #222222;
            margin-top: 20px;
            //margin-bottom: 40px;

            &.deg {
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              color: #FB030C;
              margin: 0 0 40px;
            }
          }

          @media (max-width: 767px) {

            .content {
              padding-left: 15px;
              padding-right: 15px;
            }

            h5 {
              margin-top: 30px;
            }
          }
        }

        .right_col {
          //padding: 60px 15px 60px;
          margin-left: -15px;
          margin-right: -15px;

          .custombar {
            &:after {
              display: none;
            }
          }

          .right_col_img {
            position: relative;
            padding-top: calc(600 / 500 * 100%);
          }
        }

      }
    }

    .simplebar-track.simplebar-vertical {
      display: none;
    }

    @media (max-width: 767px) {
      overflow-y: scroll !important;
      .custombar {
        position: relative;
        height: auto !important;

        .simplebar-content-wrapper {
          overflow: auto;
        }
      }

      .modal-content {
        overflow: hidden !important;
      }

      .row {
        //flex-direction: column-reverse;

        .left_col {
          padding: 0 0px 40px !important;
        }

        .right_col {
          //padding: 40px 15px !important;
        }
      }

    }

    @media (min-width: 767px) {
      .main_scroll {
        position: relative;
        height: 100% !important;

        .simplebar-content-wrapper {
          overflow: scroll;
        }
      }
    }
  }

  //modal-video

  .modal-video {
    @media(max-width: 767px){
      .modal-video-body{
        .modal-video-inner{
          .modal-video-movie-wrap{
            top: -30% !important;
          }
        }
      }
    }
  }
  .modal_video_popup {
    .modal-video {
      background: transparent;
      position: absolute;
      @media(max-width: 767px){
        .modal-video-body{
          .modal-video-inner{
            .modal-video-movie-wrap{
              top: 10% !important;
            }
          }
        }
      }
    }

    .modal-dialog {
      &:focus-visible {
        outline: none !important;
      }
    }

    .for-close {
      display: none;
    }

    .modal-dialog, .modal-content {
      background: transparent;
    }
  }


  //scroll-smooth shaking issue
  #smooth-content{
    will-change: transform;
  }
  
  //form upload button
  .gph_upload {
    margin-top: 30px;
    position: relative;
    cursor: pointer;
    border: none !important;
    //border-radius: 25px !important;
    overflow: hidden !important;
    padding: 12px;
    text-indent: 0 !important;
    border-radius: 25px;
    &.hide {
      &:before {
        opacity: 0;
      }
    }

    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      content: attr(text);
      border: 1px rgb(112 112 112 / 40%);
      border-radius: 25px;
      border-style: dashed;
      background: #ffffff;
      z-index: 1;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #373737;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.7s all ease;
      //padding-left: 20px;
    }
    

    @media (min-width: 1024px) and (max-width: 1280px) {
      &:after {
        //padding-left: 20px;
      }
    }
    

    &:hover {
      &:after {
        border: 1px ${hover};
        //border-radius: 25px;
        background: ${hover};
        color: #ffffff;
      }
    }
  }
  
  
  //split text
  //.split-up {
  //  white-space: pre-line;
  //}


  //toastify
  .toastify {
    position: fixed !important;
    z-index: 999999999 !important;
  }

  .toastify {
    z-index: 999999999 !important;
  }


  .Toastify__toast-container {
    z-index: 99999999 !important;
  }


  //loader
  .load{
    height: 100%;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 99999999;
    .follow{
      position: absolute;
      background-color: blue;
      height: 2px;
      width: 0;
      left: 0;
      z-index: 2;
    }
    .progress{
      position: absolute;
      left: 0;
      height: 2px;
      background-color: #1c7430;
      transition: 0.4s ease-out;
      width: 0;
    }
    .count{
      position: absolute;
      font-size: 45px;
      color: white;
    }
  }

  //page transition
  .page-transition {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #636466;
    z-index: 999999999;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.6s ease-in-out;
    transform: translateY(-100%);
    .logo {
      height: 80px;
      opacity: 1;
    }
  }

  .page-loader {
    position: fixed;
    background-color: #636466;
    width: 100vw;
    z-index: 999999999999999999;
    overflow: hidden;
    //opacity: 0;
    //height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .log-col{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .logo-wrapper{
      display: inline-block;
      position: relative;
      width: 220px;
      height: 40px;

      .cover {
        width: 100%;
        height: 40px;
        background:  #636466;
        top: 0;
        left: 0;
        right: 0;
        content: "";
        position: absolute;
        display: flex;
        align-items: center;
        transition: 0.3s all ease;
        text-align: center;
        justify-content: center;
        //padding-left: 25px;
        .p1-font{
          color:white;
          text-align: center;
          margin: 0;
        }
      }
      svg{
        height: 40px;
        opacity: 0;
      }
      /* Add these styles to your CSS file */
      .progress-bar {
        width: 60%; /* Set the desired width of the progress bar container */
        height: 10px; /* Set the desired height of the progress bar */
        background-color: #ddd; /* Set the background color of the progress bar container */
        margin-top: 10px; /* Adjust the spacing if needed */
      }

      .progress-bar-inner {
        height: 100%; /* Make the progress bar fill the entire height of the container */
        background-color: #4caf50; /* Set the color of the progress bar */
      }
    }

    .container {
      margin: 0;
    }

    &:after {
      content: '';
      background-blend-mode: multiply;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      opacity: 0.2;
    }

    h3 {
      color: white;
      letter-spacing: 0;
    }

    .pre-loader__img {
      //position: fixed;
      height: 100px;
      width: 100px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(36, 24, 67);
      z-index: 99999999;

      //flex: 1;
      margin: auto;
      opacity: 1;

      img {
        display: block;
      }

      .top {
        height: 8px;
      }

      .bottom {
        height: 30px;
        margin-top: 5px;
        animation: flip 1s linear infinite;
      }

      @keyframes flip {
        0% {
          transform: rotateY(0deg)
        }
        100% {
          transform: rotateY(180deg)
        }
      }
    }

  }
  
 

`;



