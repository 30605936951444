import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css'; // Import the styles
import {A11y, Pagination, Navigation } from 'swiper';
import NewsBox from "../NewsBox";
import Button from "../Button";
import NavigationIcon from "../NavigationIcon";
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import reactHtmlParser from "react-html-parser";
import Circle from "../Circle";
const LatestNews = ({list, title}) => {



    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
        console.log(offset, 'lgo')
    }, [])

    console.log('data', list)
    return (
        <StyledComponent className={'pb-150'}>
            <Container className={'news-ticker'}>
                <Row>
                    <Col md={12} className={'ticker'}>
                        <Circle/>
                        <h2>Latest Update</h2>
                    </Col>
                </Row>
            </Container>
            <Container fluid className={'slider'} style={{paddingLeft: offset + 15 + 'px'}}>
                <div>
                    {
                        list &&
                        <Swiper
                            modules={[Pagination, A11y, Navigation]}
                            spaceBetween={30}
                            pagination={{clickable: true}}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                            allowTouchMove={true}
                            longSwipesMs={900}
                            loop={true}
                            navigation={{
                                prevEl: '#act-prev',
                                nextEl: '#act-next',
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                            }}
                        >
                            {
                                list?.length>0 && list?.map((e,i)=>{
                                    return(
                                        <SwiperSlide key={i}>
                                            <NewsBox
                                                title={e?.data?.title}
                                                img={e?.images?.list?.find(e => e?.thumb === 'on')?.full_path}
                                                date={e?.data?.date}
                                                type={e?.data?.category_title}
                                                url={`/news/${e?.data?.slug}`}
                                            />
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>
                    }

                </div>
            </Container>
            <Container>
                <div className={'nav d-flex justify-content-between'}>
                    <Button text={'View All'} src={'/news'}/>
                    <NavigationIcon next_id={'act-next'} prev_id={'act-prev'}/>
                </div>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .ticker {
    h2 {
      color: #000000;
    }
  }
  .slider{
    padding: 40px 0 0;
    margin-bottom: 40px;
    @media(max-width: 767px){
      padding-right: 15px;
      padding-left: 15px !important;
    }
  }
  //
  //.swiper-button-prev {
  //  display: none;
  //  opacity: 0;
  //}
  //.swiper-button-next {
  //  display: none;
  //  opacity: 0;
  //}
  .swiper-pagination {
    display: none;
    opacity: 0;
  }
  @media(max-width: 767px){
    .swiper-slide{
      width: 100% !important;
    }
    .nav{
      flex-direction: column-reverse;
      .navigation_button{
        margin-bottom: 20px;
      }
    }
  }
`;

export default React.memo(LatestNews);
