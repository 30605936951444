import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";
import {ImageParallaxNoLoad} from "../ImageParallaxNoLoad";

const MyComponent = ({data}) => {
    const [offset, setOffset] = useState(90)
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        setBackgroundImage(data?.images?.list?.find((e)=>e?.background === "on")?.full_path);
    }, [data]);

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])


    console.log('data', data)

    return (
        <StyledComponent offset={offset} bg={backgroundImage}>
            <Container fluid className={'details'}>
                <Row>
                    <Col md={6} className={'details__content'}>
                        <div className={'details__content__wrap'}>
                            <span>
                               <Link to={`/product?category_id=${data?.product_data?.category_id}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.207" height="11.414" viewBox="0 0 17.207 11.414">
                                        <g id="Group_20750" data-name="Group 20750" transform="translate(-2545.288 16565.707)">
                                            <line id="Line_4" data-name="Line 4" x2="16" transform="translate(2562 -16560) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_5" data-name="Line 5" x2="5" y2="5" transform="translate(2551 -16555) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                            <line id="Line_6" data-name="Line 6" y1="5" x2="5" transform="translate(2551 -16560) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>
                                   <p>Back</p>
                               </Link>
                            </span>
                            <h2>{reactHtmlParser(data?.product_data?.title)}</h2>
                            <ul>
                                {reactHtmlParser(data?.product_data?.description)}
                            </ul>
                        </div>
                    </Col>
                    <Col md={6} className={'p-0 details__image'}>
                        <div className={'details__image__wrap'}>
                            <ImageParallaxNoLoad src={data?.images?.list?.find((e)=>e?.banner === "on")?.full_path}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-top: 80px;
  height: 100vh - 80px;
  .details{
    padding-left: ${props => props.offset || '90px'}px !important;
    background-image: url(${props => props.bg || '/images/static/pattern/black.png'});
    background-size: auto;
    background-repeat: repeat;
    &__content{
      span{
        a{
          display: flex;
          gap: 10px;
          align-items: center;
          margin-bottom: 40px;
          transition: all 0.3s ease-in-out;
          p{
            color: #ffffff;
          }
        }
        &:hover{
          a{
            gap: 15px;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      &__wrap{
        width: 75%;
        margin-top: 100px;
        h2{
          margin-bottom: 60px;
          color: #ffffff;
        }
        ul{
          li{
            color: #ffffff;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            padding-bottom: 18px;
            margin-bottom: 15px;
            border-bottom: 1px solid #FFFFFF;
          }
        }
      }
    }
    &__image{
      &__wrap{
        position: relative;
        padding-top: calc(698 / 598 * 100% - 80px);
      }
    }
  }
  @media(max-width: 767px){
    .row{
      //flex-direction: column-reverse;
      .details{
        &__content{
          width: 100%;
          &__wrap{
            margin-top: 60px;
            width: 100%;
            padding-bottom: 60px;
          }
        }
      }
    }
  }
`;

export default MyComponent;
