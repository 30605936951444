export const ApiParam = {
    TYPE: 'type',
    PAGE_ID: 'page_id',
    PAGE_SLUG: 'page_slug',
    VALUE : 'value',
    GET_SECTION : 'get_section',
    IMAGE : 'image',
    POST : 'post',
    FILE : 'file',
    GALLERY : 'gallery',
    LIMIT : 'limit',
    VARIATION : 'variation',
    SPECIFICATION : 'specification',
    KEYWORD : 'keyword',
    CATEGORY_ID:'category_id',
    CATEGORY : 'category',
};
