import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {contactForm} from "../../api/redux/contact";

const MyComponent = ({data}) => {

    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contactReducer);

    // Move useForm hook call here
    const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right", autoClose: 4000, closeOnClick: true, progress: undefined,

    });

    const handleBlur = () => {
        // Perform onBlur logic here
    };

    const handleChange = () => {
        // Perform onChange logic here
    };
    let api_services = apiEndPoints.SUBMITFORM;

    // const onSubmit = (e) => {
    //
    //
    //
    //     var formData = new FormData();
    //     formData.append('full_name', e?.name);
    //     formData.append('email', e?.email);
    //     formData.append('phone', e?.phone);
    //     formData.append('message', e?.message);
    //     formData.append('form_id', 'contact-form');
    //
    //     if (e.email !== '' && e.name !== '' && e.phone !== '') {
    //         dispatch(contactForm([api_services, formData]));
    //         // success('Successfully Submitted')
    //         reset();
    //     }
    // };
    // let count = 0;
    // const onError = (errors) => {
    //     Object.values(errors).forEach((error) => {
    //         count++;
    //     });
    //     if (count > 0) {
    //         toast.error('please fill out the correct inputs');
    //     }
    //     count = 0;
    // };
    // useEffect(() => {
    //     if (responseData && responseData?.error !== '') {
    //         error(responseData?.error?.message)
    //     }
    //     if (responseData && responseData?.success !== '') {
    //         success(responseData?.success)
    //
    //     }
    //     reset();
    //
    // }, [responseData])


    const onSubmit = async (e) => {
        try {

            var formData = new FormData();
            formData.append('full_name', e?.name);
            formData.append('email', e?.email);
            formData.append('phone', e?.phone);
            formData.append('message', e?.message);
            formData.append('form_id', 'contact-form');

            const response = await dispatch(contactForm([api_services, formData]));

            if (response && response.payload?.status === 1) {
                success(response.payload?.message);
                reset(); // Reset form fields
            } else if (response) {
                error(response?.payload?.response?.data?.message);
                reset(); // Reset form fields
            } else {
                error('Failed to submit form. Please try again later.');
            }
        }catch (error) {
            error('Failed to submit form. Please try again later.');
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        if (count > 0) {
            toast.error('please fill out the correct inputs');
        }
        count = 0;
    };


    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                <Row>
                    <Col md={6} className={'left'}>
                        <div className={'left__content'} data-lag={0.3}>
                            <h6 className={'split-up'}>{reactHtmlParser(data?.section_data?.subtitle)}</h6>
                            <h2 className={'split-up'}>{reactHtmlParser(data?.section_data?.short_desc)}</h2>
                            <p className={'split-up'}>{reactHtmlParser(data?.section_data?.description)}</p>
                        </div>
                    </Col>
                    <Col md={6} className={'right'}>
                        <Form>
                            <Form.Group className={'from-group'}>
                                <Form.Control
                                    className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("name", {
                                        required: 'Name is required',

                                    })}
                                    type="text" placeholder="Full name*"/>
                            </Form.Group>
                            <Form.Group className={'from-group d-flex'}>
                                <Form.Control
                                    className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("phone", {
                                        required: {
                                            value: true, message: 'please enter your phone first'
                                        }, pattern: {
                                            value: /^01[0-9]{9}$/,
                                            message: 'please enter a valid 11 digit phone number'
                                        }
                                    })}
                                    type="number" placeholder="Phone number*"/>
                            </Form.Group>
                            <Form.Group className={'from-group d-flex'}>
                                <Form.Control
                                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("email", {
                                        required: {
                                            value: true, message: 'please enter your email'
                                        }, pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'please enter a valid email address'
                                        }
                                    })}
                                    type="email" placeholder="Email*"/>
                            </Form.Group>
                            <textarea
                                className={formState?.errors?.email?.message ? ' form-control-lg textarea form-control' : 'textarea form-control form-control-lg'}
                                {...register('message', {
                                    // required: {
                                    //     // value:true,
                                    //     message: 'please enter your Message',
                                    // },
                                })}
                                placeholder={'Enter your message here'}/>

                            <div className="button" onClick={handleSubmit(onSubmit, onError)}>
                                <p>
                                    Submit
                                </p>
                            </div>

                        </Form>

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .left{
    &__content{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h6{
        font-size: 14px !important;
        line-height: 20px;
        color:#000000;
      }
      h2{
        margin-top: 40px;
        font-size: 40px;
        line-height: 50px;
        font-weight: 300;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 60px;
      }
      p{
        a{
          color:#ED1A3B !important;
        }
      }
    }
    @media(max-width: 767px){
      margin-bottom: 60px;
    }
  }
  .right{
    background-color: rgb(233 233 233 / 20%);
  }
  form{
    padding: 60px 50px;
    @media(max-width: 767px){
      padding: 40px 0 0 0;
    }
  }
  .from-group {
    margin-bottom: 30px;
    gap: 30px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgb(112 112 112 / 40%);
    //border-radius: 25px;
    padding-left: 0;
    height: 45px;
    //margin-bottom: 25px;
    color: #373737;
    text-indent: 15px;
    font-size: 14px;

    &::placeholder {
      font-size: 14px;
      color: #373737;
    }
    @media(max-width: 767px){
      font-size: 16px;
      
      &::placeholder{
        font-size: 16px;
      }
    }
  }


  textarea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgb(112 112 112 / 40%);
    height: 200px;
    max-height: 80px;
    min-height: 80px;
    color: #373737;
    box-shadow: none;
    outline: none;
    padding-left: 15px;

    &::placeholder {
      font-size: 14px;
      color: #373737;
   
    }
  }
  .button{
    margin-top: 30px;
    width: 100%;
    height: 50px;
    background-color: #ED1A3B;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    cursor: pointer;
    p{
      color: #ffffff;
    }
  }

`;

export default MyComponent;
