import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import {useSelector} from "react-redux";

const CircleComponent = () => {
    const circleRef = useRef(null);
    let getPost = useSelector(state => state)
    // useEffect(() => {
    //     const circleElement = circleRef.current;
    //     const { top: circleTop } = circleElement.getBoundingClientRect();
    //
    //     // Set initial scale to 0
    //     gsap.set(circleElement, { scale: 0 });
    //
    //     // Define the GSAP animation timeline
    //     const tl = gsap.timeline({
    //         scrollTrigger: {
    //             trigger: circleElement,
    //             start: 'top bottom', // Start when the top of the circle hits the bottom of the viewport
    //             end: 'bottom center', // Change this based on when you want the animation to end
    //             scrub: 1, // Scrub value determines the sensitivity to scrolling
    //             // markers: true
    //         },
    //     });
    //
    //     // Add the scaling animation with an elastic ease
    //     tl.to(circleElement, { scale: 1, duration: 1, ease: 'power1.inOut' });
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [getPost]); // Only run this effect once when the component mounts


    const circleStyle = {
        width: '15px',
        height: '15px',
        borderRadius: '50%',
        backgroundColor: '#ED1A3B',
    };

    return <div className={'circle'} ref={circleRef} style={circleStyle}></div>;
};

export default React.memo(CircleComponent);
