import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import ProductBox from "./ProductBox";
import reactHtmlParser from "react-html-parser";
import {useLocation, useNavigate} from "react-router-dom";

const MyComponent = ({data}) => {
    const [activeFilter, setActiveFilter] = useState('');
    const [filteredProducts, setFilteredProducts] = useState(data?.product_list);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Extract category slug from URL
        const params = new URLSearchParams(location.search);
        const categorySlug = params.get('category');
        setActiveFilter(categorySlug);
    }, [location.search]);

    const handleFilterClick = (filterSlug) => {
        setActiveFilter(filterSlug);
        // Update URL with category slug
        navigate(`?category=${filterSlug}`);
    };

    useEffect(() => {
        if (activeFilter && data?.product_list) {
            const filtered = data.product_list.filter(product => product.product_data.category_slug === activeFilter);
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts(data?.product_list);
        }
    }, [activeFilter, data?.product_list]);

    return (
        <StyledComponent className={'pb-150'}>
            <Container fluid className={'filters'}>
                <div className={'filter'}>
                    {data?.product_category && data.product_category?.length > 0 && data.product_category.map((filter, index) => (
                        <div
                            key={index}
                            className={`filter__single ${activeFilter === filter.slug ? 'active' : ''}`}
                            onClick={() => handleFilterClick(filter.slug)}
                        >
                            {window?.innerWidth > 767 ?
                                <h4>{reactHtmlParser(filter?.description)}</h4>
                                :
                                <h4>{reactHtmlParser(filter?.title)}</h4>
                            }
                        </div>
                    ))}
                </div>
            </Container>
            <Container className={'pt-100'}>
                <Row>
                    {filteredProducts && filteredProducts.length > 0 &&
                        filteredProducts.map((product, index) => (
                            <Col md={3} key={index}>
                                <ProductBox
                                    title={product.product_data.title}
                                    img={product.images.list.find(image => image.thumb === 'on')?.full_path}
                                    url={`/product/${product.product_data.slug}`}
                                />
                            </Col>
                        ))}
                </Row>
            </Container>
        </StyledComponent>
    );
};
const StyledComponent = styled.section`
  background-color: #E9E9E9;
  .filters{
    padding: 60px 0;
    background-color: #ffffff;
    cursor: pointer;
    .filter{
      display: flex;
      justify-content: center;
      gap: 40px;
      &__single{
        background-color: #636466;
        padding: 21px 45px;
        text-align: center;
        h4{
          color: #FFFFFF;
          text-transform: uppercase;
        }
      }
      @media(max-width: 767px){
        display: block !important;
        padding: 15px;
        &__single{
          margin-bottom: 30px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
    .filter__single.active {
      background-color: #ED1A3B; /* Define your active filter color */
    }
  }
  .col-md-3{
      margin-bottom: 30px;
      
      @media(max-width: 767px){
        margin-bottom: 30px;
        width: 50%;
        :nth-child(n+5) {
          margin-bottom: 30px !important;
        }
        &:last-child{
          margin-bottom: 0 !important;
        }
      }
    }
`;

export default React.memo(MyComponent);
