import React from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";


const Button = ({
                    onSubmit,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    target,
                    borderColor, link, hoverColor
                }) => {

    return (
        <StyledBtn className={`dc-btn`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
                   hoverColor={hoverColor}
        >
            {src ? (
                <Link to={src || '/'}>
                    <p>{text}</p>
                </Link>

            ) : (
                <a target={target || '_self'}><p>{text}</p></a>
            )}
        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: fit-content;
    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      color: ${p => p.color || '#636466'};
      font-size: ${props => props.fontSize || '14'}px;
      font-weight: ${props => props.fontWeight || 400};
      margin: 0;
      padding: 12px 36px 12px 30px;
      line-height: ${props => props.lineHeight || '20'}px;
      text-transform: uppercase;
      background-color: ${props => props.background || `transparent`};
      letter-spacing: ${props => props.letterSpacing || `-0.5px`};
      position: relative;
      border-radius: ${props => props.borderRadius || '0'};
      z-index: 0;
      transition: padding 0.5s ease, border .5s cubic-bezier(.54,.32,.05,.96);
      p{
        color: ${p => p.color || '#636466'};
        position: relative;
        z-index: 0;
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px; /* Adjust the height of the underline */
          background-color: ${p => p.hoverColor || '#636466'};
          bottom: 0;
          left: 0;
          transform-origin: bottom right;
          transform: scaleX(0);
          transition: transform 0.3s ease-in-out;
        }
      }
      &:before {
        position: absolute;
        content: '';
        height: 40px;
        width: 100%;
        box-shadow: 0 0 0 1px ${p => p.borderColor || '#636466'};
        left: 0;
        border-radius: 20px;
        transition: all .5s cubic-bezier(.54,.32,.05,.96);
        z-index: -1;
        animation: button-opacity 4.5s infinite;

      }
      

      a {
        &:after {
          display: none;
        }

        &:before {
          display: none;
        }
      }
      

      &:hover {
        color: ${p => p.hoverColor || '#636466'} !important;
        //text-decoration: underline;
        padding-left: 20px;
        transition: padding 0.5s ease, border .5s cubic-bezier(.54,.32,.05,.96);
        &:before {
          position: absolute;
          content: '';
          height: 40px;
          width: 40px;
          box-shadow: 0 0 0 1px ${p => p.borderColor || '#636466'};
          left: 0;
          border-radius: 20px;
          transition: all .5s cubic-bezier(.54,.32,.05,.96);
          z-index: -1;
          animation: button-opacity 4.5s infinite;
        }
        p{
          &:after {
            transform-origin: bottom left;
            transform: scaleX(1);
          }
        }
      }


    }
  }

  @media (max-width: 768px) {
    a {
      //width: 165px !important;
      justify-content: center !important;

      &:before {
        //width: 100% !important;
      }

      &:after {
        display: none;
      }
    }
  }



`;


export default React.memo(Button);
