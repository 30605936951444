import React from 'react';
import styled from "styled-components";
import SubTitle from "../Title";
import {Container, Row, Col} from "react-bootstrap";
import {
    LightgalleryProvider,
    LightgalleryItem,
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css"
import Circle from "../Circle";
import reactHtmlParser from "react-html-parser";



const Gallery = ({data}) => {

    const PhotoItem = ({image, thumb, group}) => (
        <LightgalleryItem group={group} src={image} thumb={thumb}>
            <img src={image} alt=''/>
        </LightgalleryItem>

    );

    return (
        <StyledGallery className='pt-150 pb-150'>
            <Container>
                <Row>
                    <Col>
                        <Circle/>
                        <h2>{reactHtmlParser(data?.section_data?.subtitle)}</h2>
                    </Col>
                </Row>
            </Container>

            <Container fluid className='p-0'>
                <LightgalleryProvider
                    lightgallerySettings={
                        {
                            download: false,
                            thumbnail: false,
                            fullScreen: false,
                            share: false
                        }
                    }
                >
                    <Row>
                        {
                            data?.images?.list && data?.images?.list?.length>0 &&
                            data?.images?.list?.map((e,i)=>{
                                return(
                                    <Col key={i} sm={3}>
                                        <div className="gallery-single">
                                            <PhotoItem image={e?.full_path} group="group1"/>
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </LightgalleryProvider>
            </Container>
        </StyledGallery>
    );
};

const StyledGallery = styled.section`
  position: relative;
  background-color: #F7F7F9;
  h2{
    color: #000000;
    margin-bottom: 40px;
  }

  .col-sm-3 {
    padding: 0 5px;
    margin-bottom: 10px;
  }

  .gallery-single {
    padding-top: calc(301 / 334 * 100%);
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  @media (max-width: 767px) {
    .bg-shadow{
      width: 100%;
    }

    .col-sm-3 {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0px 7.5px;
      margin-bottom: 15px;
      &:nth-last-child(1){
        margin-bottom: 0;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }

    .sub-title {
      margin-bottom: 60px;
    }
  }

`;
export default Gallery;
