import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom"
import Title from "./Title";
import ReactHtmlParser from "react-html-parser";
import Circle from "./Circle";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/swiper-bundle.css'; // Import the styles
import {A11y, Pagination, Autoplay } from 'swiper';
import reactHtmlParser from "react-html-parser";

const Clients = ({data,id,padding, list}) => {



    return (
        <StyledComponent className={`${padding ? padding : 'pt-150 pb-150'}`} id={`${id ? id : 'Associates'}`}>
            <Container className={'associates'}>
                <Row>
                    <Col md={12} className={'associates__top'}>
                        <Row className={'associates__top__details'}>
                            <Col md={12}>
                                <Circle/>
                            </Col>
                            <Col md={6}>
                                <h2 className={'split-up'}>{reactHtmlParser(data?.subtitle)}</h2>
                            </Col>
                            <Col md={6}>
                                {reactHtmlParser(data?.short_desc)}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container fluid className={'associates__bottom'}>
                <div>
                    {
                        list &&
                        <Swiper
                            modules={[Pagination, A11y, Autoplay]}
                            spaceBetween={0}
                            slidesPerView={1}
                            navigation
                            pagination={{clickable: true}}
                            loop={true}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                            longSwipesMs={900}
                            autoplay={{
                                delay: 4000,
                                disableOnInteraction: false,
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 2, // Show 2 slides in mobile
                                    spaceBetween: 0,
                                },
                                768: {
                                    slidesPerView: 4,
                                    spaceBetween: 0,
                                },
                            }}
                        >
                            {
                                list?.length>0 && list?.map((e,i)=>{
                                    return(
                                        <SwiperSlide key={i}>
                                            <div className="associates__bottom__single" key={0}>
                                                {/* Conditionally render the link */}
                                                {e?.data?.subtitle ? (
                                                    <a href={e.data.subtitle} target={'_blank'}>
                                                        <div className={'associates__bottom__single__inner'}>
                                                            <img src={e?.images?.[0]?.full_path} alt=""/>
                                                        </div>
                                                    </a>
                                                ) : (
                                                    // Render a placeholder if no subtitle data
                                                    <div className={'associates__bottom__single__inner'}>
                                                        <img src={e?.images?.[0]?.full_path} alt=""/>
                                                    </div>
                                                )}
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>
                    }

                </div>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`

  .subtitle{
    p{
      text-transform: capitalize;
      font-weight: 400;
      color: rgb(79, 97, 107);
      margin: 0px 0px 20px;
      position: relative;
      width: 100%;
      padding-bottom: 20px;
      &:after{
        position: absolute;
        left: 0px;
        width: 100%;
        content: "";
        background: rgb(79, 97, 107);
        height: 1px;
        bottom: 0px;
      }
    }
  }


  .associates{
    //padding-top: 120px;
    @media(max-width: 767px){
      padding: 0 15px;
    }
    &__top{
      padding-bottom: 80px;
      @media(max-width: 767px){
        padding-bottom: 40px;
      }
      &__details{
        display: flex;
        padding-top: 80px;

        @media(min-width: 1440px){
          .title{
            width: 70%;
          }
        }
        h2 {
          color: #000000;
        }
        p{
          
          font-size: 14px;
          line-height: 21px;
          color: #636466;
          text-transform: inherit;
        }
        @media(max-width: 767px){
          padding-top: 15px;
          p{
            padding-left: 0px;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
.associates__bottom {
  border-bottom: 1px solid #E9E9E9;

  &__single {
    //border-right: 1px solid #E9E9E9;
    //border-top: 1px solid #E9E9E9;

    &:last-child {
      border-right: 0px
    }

    //@media (max-width: 767px) {
    //  max-width: 50%;
    //  border-right: 0;
    //  //border-top: 1px solid #4F616B;
    //  //border-bottom: 1px solid #4F616B;
    //}

    &__inner {
      padding-top: 100%;
      position: relative;

      img {
        max-width: 80%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
      }
    }
  }
  
}

  .swiper-slide {
    border-right: 1px solid #E9E9E9;
    border-top: 1px solid #E9E9E9;

    &:last-child {
      border-right: 0px
    }
   
  }

  .swiper-button-prev {
    display: none;
    opacity: 0;
  }
  .swiper-button-next {
    display: none;
    opacity: 0;
  }
  .swiper-pagination {
    display: none;
    opacity: 0;
  }
  
`;

export default React.memo(Clients);
