import React, { useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { TransitionProvider } from '../context/TransitionContext';
import TransitionComponent from '../components/Transition';
import About from '../pages/about';
import Components from '../pages/components';
import Home from '../pages/home';
import Product from '../pages/product';
import ProductDetails from '../pages/product/single';
import FactoryProfile from "../pages/factory-profile";
import Sustainability from "../pages/sustainability";
import Newsevents from "../pages/newsevents";
import NewsDetails from "../pages/newsevents/single";
import Contactus from "../pages/contactus";
import Career from "../pages/career";
import Error from '../pages/404';

const Router = () => {
  const location = useLocation();
  return (

      <TransitionProvider>
        <Routes>
          <Route exact
                 index
                 element={
                   <TransitionComponent>
                     <Home />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/about'
                 element={
                   <TransitionComponent>
                     <About />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/product'
                 element={
                   <TransitionComponent>
                     <Product />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/product/:slug'
                 element={
                   <TransitionComponent>
                     <ProductDetails />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/factory-profile'
                 element={
                   <TransitionComponent>
                     <FactoryProfile />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/sustainability'
                 element={
                   <TransitionComponent>
                     <Sustainability />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/career'
                 element={
                   <TransitionComponent>
                     <Career />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/contact'
                 element={
                   <TransitionComponent>
                     <Contactus />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/news'
                 element={
                   <TransitionComponent>
                     <Newsevents />
                   </TransitionComponent>
                 }
          />
          <Route exact
                 path='/news/:slug'
                 element={
                   <TransitionComponent>
                     <NewsDetails />
                   </TransitionComponent>
                 }
          />
          {/* Other routes */}
          <Route path="*" element={<Error />} />
        </Routes>
      </TransitionProvider>

  );
};

export default Router;
