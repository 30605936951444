import {Img} from "./Img";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import {ImgInnerBanner} from "./ImgInnerBanner";
import {ImageParallaxNoLoad} from "./ImageParallaxNoLoad";

const InnerBanner = ({title, img, full}) => {


    return (
        <StyledInnerBanner full={full} className="InnerBanner">
            <div className="InnerBanner__parallax">
                <ImageParallaxNoLoad src={img} alt=""/>
                <Container>
                    <Row>
                        <Col md={12}>
                            {
                                title &&
                                <h1>{ReactHtmlParser(title)}</h1>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  position: relative;
  //&:before{
  //  position: absolute;
  //  content: '';
  //  top: 0;
  //  left: 0;
  //  height: 100%;
  //  width: 100%;
  //  background-color: rgb(0 0 0 / 50%);
  //}
  overflow: hidden;
  .InnerBanner__parallax {
    //padding-top: ${props => props.full ? '100vh' : '70vh'};
    padding-top: calc(500 / 1366 * 100%);
    position: relative;
    @media (min-width: 1550px) {
      padding-top: ${props => props.full ? '100vh' : '60vh'};
    }
    @media (max-width: 767px) {
      padding-top: ${props => props.full ? '100vh' : 'calc(450 / 414 * 100%)'} ;
    }

    .container {
      position: absolute;
      margin: auto;
      left: 50%;
      z-index: 2;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      h1 {
        color: #ffffff;
        z-index: 2;
        //font-size: 48px;
        //font-weight: 600;
        //line-height: 52px;
        margin: 0;
        text-transform: capitalize;
        @media (max-width: 767px) {
          //font-size: 30px;
          //line-height: 35px;
        }
      }
    }
  }
`;

export default InnerBanner;
