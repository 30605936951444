import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'pb-150'}>
            <Container>
                {data?.posts?.list && data?.posts?.list?.length>0 &&
                    data?.posts?.list?.map((row, index) => (
                    <Row key={index + 1} className={`values ${index % 2 === 1 ? 'even-row' : 'odd-row'}`}>
                        <Col md={index % 2 === 1 ? {span:5, offset:1} : 5} className={'values__content'}>
                            <div className={'content-wrapper'}>
                                <h2>{row.data?.subtitle}</h2>
                                {reactHtmlParser(row?.data?.description)}
                            </div>
                        </Col>
                        <Col
                            md={index % 2 === 1 ? 6 : {span:6,offset:1}}
                            className={'values__image'}
                        >
                            <div className={'img-wrapper just-reveal'}>
                                <Img src={row.images?.[0]?.full_path} />
                            </div>
                        </Col>
                    </Row>
                ))}
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .values{
    margin-bottom: 100px;
    &:last-child{
      margin-bottom: 0;
    }
    &:nth-child(even){
      flex-direction: row-reverse;
    }
    &__content{
      .content-wrapper{
        
        h2{
          margin-bottom: 40px;
          color: #000000;
        }
        p{
          margin-bottom: 21px !important;
          color: #636466;
          &:last-child{
            margin-bottom: 0;
          }
        }
        ul{
          li{
            color: #000000;
            font-size: 18px;
            line-height: 24px;
            font-weight: 300;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px solid rgb(99 100 102 / 25%);
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &__image{
      .img-wrapper{
        position: relative;
        padding-top: calc(450 / 570 * 100%);
        overflow: hidden;
      }
    }
  }
`;

export default MyComponent;
